import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

import { Form, Result } from "antd"
import Body from "components/atom/body"
import Button from "components/atom/button"
import Grid, { GridSize } from "components/atom/grid"
import Layout from "components/atom/layout"
import Text from "components/atom/text"
import FormInput from "components/molecule/form_input"
import Footer from "components/organism/footer"
import Header from "components/organism/header"
import qs from "qs"

import StateMachine from "@/libs/statemachine"
import { FeatureExecuter } from "@/store/executer/Action"
import { useExecuterStore } from "@/store/executer/Hooks"

import { ReactComponent as WelcomeSvg } from "./assets/welcome.svg"

const UserFirstAccess = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const params: any =
    qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

  const {
    clean,
    data,
    executeAction: executeFirstAccess,
    viewState,
  } = useExecuterStore({
    feature: FeatureExecuter.AccountFirstAccess,
  })

  useEffect(() => {
    return () => clean()
  }, [])

  const onSubmit = async (form: any) => {
    await executeFirstAccess({
      bodyParams: { password: form.password, token: form.token },
    })
  }

  return (
    <div>
      <Layout>
        <Header />
        <Body>
          <Grid size={GridSize.Two}>
            <div className="hidden md:flex justify-center">
              <WelcomeSvg />
            </div>

            <div className="_pt-6 _pl-8 _pr-8">
              {StateMachine.isLoaded(viewState) ? (
                <Result
                  extra={[
                    <Link key="signin" to="/signin">
                      <Button key="console" theme="primary">
                        Ir para o login
                      </Button>
                    </Link>,
                  ]}
                  status="success"
                  subTitle="Agora você já pode acessar a suas reservas."
                  title="Pronto!"
                />
              ) : (
                <>
                  <div className="_text-center _mb-6">
                    <Text className="_text-6 block">Bem vindo,</Text>
                    <Text className="_text-4">ao seu primeiro acesso</Text>
                  </div>

                  <Form
                    initialValues={{ token: params.token }}
                    layout="vertical"
                    name="basic"
                    onFinish={onSubmit}
                  >
                    <FormInput
                      disabled={StateMachine.isLoading(viewState)}
                      hidden={true}
                      name="token"
                    />

                    <FormInput
                      disabled={StateMachine.isLoading(viewState)}
                      label="Digite sua senha"
                      min={8}
                      name="password"
                      required
                      type="password"
                    />

                    <FormInput
                      customRules={[
                        ({ getFieldValue }: any) => ({
                          validator(rule: any, value: any) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              "As duas senha devem ser iguais!",
                            )
                          },
                        }),
                      ]}
                      disabled={StateMachine.isLoading(viewState)}
                      label="Confirme sua senha"
                      min={8}
                      name="confirmpassword"
                      required
                      type="password"
                    />

                    {StateMachine.isError(viewState) && (
                      <div className="mb-4 text-center">
                        <Text className="text-base text-red-500 font-bold">
                          {t(data.key)}
                        </Text>
                      </div>
                    )}

                    <Form.Item>
                      <Button
                        block
                        htmlType="submit"
                        loading={StateMachine.isLoading(viewState)}
                        theme="primary"
                      >
                        Confirmar senha
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </Grid>
        </Body>
        <Footer />
      </Layout>
    </div>
  )
}

export default UserFirstAccess

import React from "react"

import { Form, Input } from "antd"
import Text from "components/atom/text"

interface FormInputMoneyProps {
  disabled?: boolean
  extra?: any
  label: string
  max?: number
  min?: number
  name: string
  required?: boolean
}

const baseClassNames =
  "rounded-md bg-white text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"

const FormInputMoney: React.FC<FormInputMoneyProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  if (props?.min) {
    rules.push({
      message: `Este campo deve ter no mínimo ${props?.min} letras.`,
      min: props?.min,
    })
  }

  if (props?.max) {
    rules.push({
      max: props?.max,
      message: `Este campo deve ter no máximo ${props?.max} letras.`,
    })
  }

  return (
    <div>
      <Text className="block text-sm/6 font-semibold text-gray-900 mb-1">
        {props.label}
        {props.required && " *"}
      </Text>

      <Form.Item
        extra={props.extra}
        hasFeedback
        name={props.name}
        rules={rules}
      >
        <Input
          className={baseClassNames}
          disabled={props?.disabled || false}
          prefix="R$"
          size="large"
        />
      </Form.Item>
    </div>
  )
}

export default FormInputMoney

import { authApi } from "./auth"
import { baseApi } from "./base"
import { discountApi } from "./discount"
import { vehicleApi } from "./vehicle"

// type ApiGet = {
//   get: {
//     url: string
//   }
// }

// type MappedApis = {
//   auth: {
//     changePassword: string
//     check: ApiGet
//     forgotPassword: string
//     refresh: ApiGet
//     signin: {
//       post: string
//     }
//     signup: {
//       post: string
//     }
//   }
//   base: {}
//   vehicle: {
//     manualBooking: {
//       delete: string
//     }
//   }
// }

// TODO; Think in a better way;
type PathApi =
  | `/${string}`
  | `/${string}/${string}`
  | `/${string}/${string}/${string}`
  | `/${string}/${string}/${string}/${string}`
  | `/${string}/${string}/${string}/${string}/${string}`

const createApi = (baseApi: string) => {
  return {
    createEndpoint: (path?: PathApi) => {
      if (path === "/") return baseApi

      return `${baseApi}${path}`
    },
  }
}

export const mappedApis = {
  get auth() {
    const api = createApi(authApi())

    return {
      changePassword: {
        put: {
          url: api.createEndpoint("/v1/changePassword"),
        },
      },
      check: {
        get: {
          url: api.createEndpoint("/v1/check"),
        },
      },
      firstAccess: {
        put: {
          url: api.createEndpoint("/v1/firstAccess"),
        },
      },
      forgotPassword: {
        post: {
          url: api.createEndpoint("/v1/requestForgotPassword"),
        },
      },
      refresh: {
        get: {
          url: api.createEndpoint("/v1/refresh"),
        },
      },
      signin: {
        post: {
          url: api.createEndpoint("/v1/signin"),
        },
      },
      signinAdmin: {
        post: {
          url: api.createEndpoint("/v1/signin/admin"),
        },
      },
      signup: {
        post: {
          url: api.createEndpoint("/v1/signup"),
        },
      },
    }
  },
  get base() {
    const baseEntity = baseApi()

    return {
      get account() {
        const api = createApi(`${baseEntity}/v1/account`)

        return {
          account: {
            get: {
              url: api.createEndpoint("/"),
            },
          },
          cep: {
            get: {
              url: api.createEndpoint("/cep"),
            },
          },
          check: {
            post: {
              url: api.createEndpoint("/check"),
            },
          },
          companyFavorite: {
            post: {
              url: api.createEndpoint("/company/favorite"),
            },
          },
          create: {
            post: {
              url: api.createEndpoint("/create"),
            },
          },
          edit: {
            put: {
              url: api.createEndpoint("/edit"),
            },
          },
          info: {
            get: {
              url: api.createEndpoint("/info"),
            },
          },
          internalCreate: {
            post: {
              url: api.createEndpoint("/internal/create"),
            },
          },
          internalValidate: {
            get: {
              url: api.createEndpoint("/internal/validate"),
            },
          },
          update: {
            put: {
              url: api.createEndpoint("/update"),
            },
          },
          validate: {
            get: {
              url: api.createEndpoint("/validate"),
            },
          },
        }
      },
      get booking() {
        const api = createApi(`${baseEntity}/v1/booking`)

        return {
          available: {
            post: {
              url: api.createEndpoint("/available"),
            },
          },
          booking: {
            get: {
              url: api.createEndpoint("/"),
            },
            post: {
              url: api.createEndpoint("/"),
            },
          },
          charge: {
            post: {
              url: api.createEndpoint("/charge"),
            },
          },
          company: {
            get: {
              url: api.createEndpoint("/company"),
            },
            post: {
              url: api.createEndpoint("/company"),
            },
          },
          pickupdropoff: {
            get: {
              url: api.createEndpoint("/pickupdropoff"),
            },
          },
          rating: {
            post: {
              url: api.createEndpoint("/rating"),
            },
          },
          user: {
            get: {
              url: api.createEndpoint("/user"),
            },
          },
        }
      },
      get company() {
        const api = createApi(`${baseEntity}/v1/company`)

        return {
          approve: {
            put: {
              url: api.createEndpoint("/approve/{uCode}"),
            },
          },
          block: {
            put: {
              url: api.createEndpoint("/block/{uCode}"),
            },
          },
          branchStatus: {
            put: {
              url: api.createEndpoint("/branch/status"),
            },
          },
          calendar: {
            get: {
              url: api.createEndpoint("/calendar"),
            },
          },
          cities: {
            get: {
              url: api.createEndpoint("/cities"),
            },
          },
          clients: {
            get: {
              url: api.createEndpoint("/clients"),
            },
          },
          company: {
            get: {
              url: api.createEndpoint("/"),
            },
            post: {
              url: api.createEndpoint("/"),
            },
            put: {
              url: api.createEndpoint("/"),
            },
          },
          companyBranch: {
            get: {
              url: api.createEndpoint("/branch"),
            },
            post: {
              url: api.createEndpoint("/branch"),
            },
            put: {
              url: api.createEndpoint("/branch"),
            },
          },
          companySimple: {
            post: {
              url: api.createEndpoint("/simple"),
            },
            put: {
              url: api.createEndpoint("/simple"),
            },
          },
          contact: {
            post: {
              url: api.createEndpoint("/contact"),
            },
          },
          dashboard: {
            get: {
              url: api.createEndpoint("/dashboard"),
            },
          },
          finance: {
            get: {
              url: api.createEndpoint("/finance"),
            },
          },
          myCities: {
            delete: {
              url: api.createEndpoint("/mycities/{id}/remove"),
            },
            get: {
              url: api.createEndpoint("/mycities"),
            },
            put: {
              url: api.createEndpoint("/mycities/{cidadeId}/{action}"),
            },
          },
          search: {
            get: {
              url: api.createEndpoint("/search"),
            },
          },
          start: {
            post: {
              url: api.createEndpoint("/start"),
            },
          },
        }
      },
      get companyBranch() {
        const api = createApi(`${baseEntity}/v1/companyBranch`)

        return {
          dropoff: {
            get: {
              url: api.createEndpoint("/dropoff"),
            },
            post: {
              url: api.createEndpoint("/dropoff"),
            },
          },
          dropoffStatus: {
            put: {
              url: api.createEndpoint("/dropoff/status"),
            },
          },
          opendays: {
            get: {
              url: api.createEndpoint("/opendays"),
            },
            post: {
              url: api.createEndpoint("/opendays"),
            },
          },
          opendaysStatus: {
            put: {
              url: api.createEndpoint("/opendays/status"),
            },
          },
          pickup: {
            get: {
              url: api.createEndpoint("/pickup"),
            },
            post: {
              url: api.createEndpoint("/pickup"),
            },
          },
          pickupStatus: {
            put: {
              url: api.createEndpoint("/pickup/status"),
            },
          },
        }
      },
      get creditcard() {
        const api = createApi(`${baseEntity}/v1/creditcard`)

        return {
          create: {
            post: {
              url: api.createEndpoint("/create"),
            },
          },
          creditcard: {
            get: {
              url: api.createEndpoint("/{uCode}"),
            },
          },
          default: {
            put: {
              url: api.createEndpoint("/default/{uCode}"),
            },
          },
          list: {
            get: {
              url: api.createEndpoint("/"),
            },
          },
        }
      },
      get document() {
        const api = createApi(`${baseEntity}/v1/document`)

        return {
          signed: {
            post: {
              url: api.createEndpoint("/signed"),
            },
          },
        }
      },
      get places() {
        const api = createApi(`${baseEntity}/v1/places`)

        return {
          airports: {
            get: {
              url: api.createEndpoint("/airports"),
            },
          },
          pickupdropoff: {
            get: {
              url: api.createEndpoint("/pickupdropoff"),
            },
          },
          places: {
            get: {
              url: api.createEndpoint("/"),
            },
          },
        }
      },
    }
  },
  get discount() {
    const api = createApi(discountApi())

    return {
      create: {
        url: api.createEndpoint("/v1"),
      },
      list: {
        url: api.createEndpoint("/v1"),
      },
      status: {
        url: api.createEndpoint("/v1/status"),
      },
      update: {
        url: api.createEndpoint("/v1"),
      },
      usage: {
        url: api.createEndpoint("/v1/usage"),
      },
    }
  },
  get partner() {
    return {
      ip: {
        get: {
          url: "https://api.ipify.org",
        },
      },
    }
  },
  get vehicle() {
    const api = createApi(vehicleApi())

    return {
      all: {
        get: {
          url: api.createEndpoint("/v1/vehicle/all"),
        },
      },
      available: {
        get: {
          url: api.createEndpoint("/v1/vehicle/available"),
        },
      },
      availableBranch: {
        get: {
          url: api.createEndpoint("/v1/vehicle/branch/available"),
        },
      },
      bookingCancel: {
        post: {
          url: api.createEndpoint("/v1/vehicle/cancelrent/{uCode}"),
        },
      },
      bookingConfirm: {
        post: {
          url: api.createEndpoint("/v1/vehicle/rent/confirm/{uCode}"),
        },
      },
      bookingEnd: {
        post: {
          url: api.createEndpoint("/v1/vehicle/endrent/{uCode}"),
        },
      },
      bookingExtraInfo: {
        post: {
          url: api.createEndpoint("/v1/vehicle/extrainforent/{uCode}"),
        },
      },
      bookingNoShow: {
        post: {
          url: api.createEndpoint("/v1/vehicle/noshow/{uCode}"),
        },
      },
      bookingReject: {
        post: {
          url: api.createEndpoint("/v1/vehicle/rent/reject/{uCode}"),
        },
      },
      bookingStart: {
        post: {
          url: api.createEndpoint("/v1/vehicle/startrent/{uCode}"),
        },
      },
      calendar: {
        get: {
          url: api.createEndpoint("/v1/vehicle/calendar/{uCode}"),
        },
      },
      disable: {
        put: {
          url: api.createEndpoint("/v1/vehicle/disable/{uCode}/{type}"),
        },
      },
      enable: {
        put: {
          url: api.createEndpoint("/v1/vehicle/enable/{uCode}/{type}"),
        },
      },
      finance: {
        get: {
          url: api.createEndpoint("/v1/vehicle/finance"),
        },
      },
      manualBooking: {
        delete: {
          url: api.createEndpoint("/v1/vehicle/manualBook/{uCode}"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/manualBook"),
        },
      },
      remove: {
        put: {
          url: api.createEndpoint("/v1/vehicle/remove/{uCode}/{type}"),
        },
      },
      vehicle: {
        get: {
          url: api.createEndpoint("/v1/vehicle"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle"),
        },
        put: {
          url: api.createEndpoint("/v1/vehicle"),
        },
      },
      vehicleGroup: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group"),
        },
        put: {
          url: api.createEndpoint("/v1/vehicle/group"),
        },
      },
      vehicleGroupBlockDates: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/blockDates"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/blockDates"),
        },
      },
      vehicleGroupBlockDatesStatus: {
        put: {
          url: api.createEndpoint("/v1/vehicle/group/blockDates/status"),
        },
      },
      vehicleGroupBookingAdditional: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/booking/additional"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/booking/additional"),
        },
        put: {
          url: api.createEndpoint("/v1/vehicle/group/booking/additional"),
        },
      },
      vehicleGroupBookingAdditionalLinking: {
        get: {
          url: api.createEndpoint(
            "/v1/vehicle/group/booking/additional/linking",
          ),
        },
        post: {
          url: api.createEndpoint(
            "/v1/vehicle/group/booking/additional/linking",
          ),
        },
      },
      vehicleGroupBookingAdditionalLinkingStatus: {
        put: {
          url: api.createEndpoint(
            "/v1/vehicle/group/booking/additional/linking/status",
          ),
        },
      },
      vehicleGroupBookingAdditionalStatus: {
        put: {
          url: api.createEndpoint(
            "/v1/vehicle/group/booking/additional/status",
          ),
        },
      },
      vehicleGroupBranchBlockDates: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/branch/blockDates"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/branch/blockDates"),
        },
      },
      vehicleGroupBranchBlockDatesStatus: {
        put: {
          url: api.createEndpoint("/v1/vehicle/group/branch/blockDates/status"),
        },
      },
      vehicleGroupManualBook: {
        delete: {
          url: api.createEndpoint("/v1/vehicle/group/manualBook/{uCode}"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/manualBook"),
        },
      },
      vehicleGroupPrice: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/price"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/price"),
        },
      },
      vehicleGroupPriceDates: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/priceDates"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/priceDates"),
        },
      },
      vehicleGroupPriceDatesStatus: {
        put: {
          url: api.createEndpoint("/v1/vehicle/group/priceDates/status"),
        },
      },
      vehicleGroupPriceStatus: {
        put: {
          url: api.createEndpoint("/v1/vehicle/group/price/status"),
        },
      },
      vehicleGroupProtectionAndEquipment: {
        get: {
          url: api.createEndpoint("/v1/vehicle/protandequip"),
        },
      },
      vehicleGroupStock: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/stock"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/stock"),
        },
      },
      vehicleGroupStockStatus: {
        put: {
          url: api.createEndpoint("/v1/vehicle/group/stock/status"),
        },
      },
      vehicleGroupType: {
        get: {
          url: api.createEndpoint("/v1/vehicle/group/type"),
        },
        post: {
          url: api.createEndpoint("/v1/vehicle/group/type"),
        },
        put: {
          url: api.createEndpoint("/v1/vehicle/group/type"),
        },
      },
      vehicleGroupTypeStatus: {
        put: {
          url: api.createEndpoint("/v1/vehicle/group/type/status"),
        },
      },
    }
  },
}

import { StateMachineType } from "libs/statemachine"
import { Dispatch } from "redux"
import slugify from "slugify"
import * as GetterEffect from "store/getter/Effect"

import { mappedApis } from "@/libs/fetch/apis"
import Utils from "@/libs/utils"

const signalRequests: Record<string, AbortController> = {}

export enum FeatureGetter {
  GetIpClient = "GET_IP_CLIENT",
  AdminDashboard = "ADMIN_DASHBOARD",
  GetAccountValidate = "GET_ACCOUNT_VALIDATE",
  GetAccountInternalValidate = "GET_ACCOUNT_INTERNAL_VALIDATE",
  GetBooking = "GET_BOOKING",
  GetBookingCompany = "GET_BOOKING_COMPANY",
  GetBookingUser = "GET_BOOKING_USER",
  GetBookingPickupDropoff = "GET_BOOKING_PICKUP_DROPOFF",
  GetBookingIntegration = "GET_BOOKING_INTEGRATION",
  GetCities = "GET_CITIES",
  GetCompany = "GET_COMPANY",
  GetCompanySearch = "GET_COMPANY_SEARCH",
  GetCompanyDashboard = "COMPANY_DASHBOARD",
  GetCompanyBranch = "GET_COMPANY_BRANCH",
  GetCompanyCities = "GET_COMPANY_CITIES",
  GetCompanyClients = "GET_COMPANY_CLIENTS",
  GetCompanyCalendar = "COMPANY_CALENDAR",
  GetCompanyFinance = "GET_COMPANY_FINANCE",
  GetCompanyVehicleCalendar = "COMPANY_VEHICLE_CALENDAR",
  GetCEP = "GET_CEP",
  GetAirport = "GET_Airport",
  GetDiscount = "GET_DISCOUNT",
  GetDiscountUsage = "GET_DISCOUNT_USAGE",
  GetPlaces = "GET_PLACES",
  GetPlacesPickupDropoff = "GET_PLACES_PICKUP_DROPOFF",
  GetUser = "GET_USER",
  GetUserCreditCard = "GET_USER_CREDIT_CARD",
  GetVehicle = "GET_VEHICLE",
  GetVehicleAvailable = "GET_VEHICLE_AVAILABLE",
  GetVehicleStoreAvailable = "GET_VEHICLE_STORE_AVAILABLE",
  GetVehicleAllDetail = "GET_VEHICLE_ALL_DETAIL",
  GetVehicleGroup = "GET_VEHICLE_GROUP",
  GetVehicleGroupType = "GET_VEHICLE_GROUP_TYPE",
  GetVehicleGroupAvailability = "GET_VEHICLE_GROUP_AVAILABILITY",
  GetVehicleCalendar = "VEHICLE_CALENDAR",
  GetVehicleProtectionAndEquipment = "GET_VEHICLE_PROTECTION_AND_EQUIPMENT",
  GetVehicleGroupBookingAdditional = "GET_VEHICLE_GROUP_BOOKING_ADDITIONAL",
  GetVehicleGroupBookingAdditionalLinking = "GET_VEHICLE_GROUP_BOOKING_ADDITIONAL_LINKING",
  GetVehicleGroupPrice = "GET_VEHICLE_GROUP_PRICE",
  GetVehicleGroupPriceDates = "GET_VEHICLE_GROUP_PRICE_DATES",
  GetVehicleGroupStock = "GET_VEHICLE_GROUP_STOCK",
  GetCompanyBranchPickups = "GET_COMPANY_BRANCH_PICKUPS",
  GetCompanyBranchDropoffs = "GET_COMPANY_BRANCH_DROPOFFS",
  GetCompanyBranchOpenDays = "GET_COMPANY_BRANCH_OPENDAYS",
  GetCompanyBranchBlockDates = "GET_COMPANY_BLOCK_DATES",
  GetVehicleGroupBlockDates = "VEHICLE_GROUP_BLOCK_DATES",
}

export const features: Record<
  string,
  { url: string; defaultValue?: any; keyText?: string; keyValue?: string }
> = {
  [FeatureGetter.AdminDashboard]: {
    url: "/v1/admin/dashboard", // TODO: fix;
  },
  [FeatureGetter.GetAccountInternalValidate]: {
    url: mappedApis.base.account.internalValidate.get.url,
  },
  [FeatureGetter.GetAccountValidate]: {
    url: mappedApis.base.account.validate.get.url,
  },
  [FeatureGetter.GetAirport]: {
    defaultValue: [],
    url: mappedApis.base.places.airports.get.url,
  },
  [FeatureGetter.GetBooking]: {
    defaultValue: [],
    url: mappedApis.base.booking.booking.get.url,
  },
  [FeatureGetter.GetBookingCompany]: {
    defaultValue: [],
    url: mappedApis.base.booking.company.get.url,
  },
  [FeatureGetter.GetBookingIntegration]: {
    url: "/v1/booking/integration", // TODO: fix;
  },
  [FeatureGetter.GetBookingPickupDropoff]: {
    url: mappedApis.base.booking.pickupdropoff.get.url,
  },
  [FeatureGetter.GetBookingUser]: {
    url: mappedApis.base.booking.user.get.url,
  },
  [FeatureGetter.GetCEP]: {
    url: mappedApis.base.account.cep.get.url,
  },
  [FeatureGetter.GetCities]: {
    url: mappedApis.base.company.cities.get.url,
  },
  [FeatureGetter.GetCompany]: {
    url: mappedApis.base.company.company.get.url,
  },
  [FeatureGetter.GetCompanyBranch]: {
    url: mappedApis.base.company.companyBranch.get.url,
  },
  [FeatureGetter.GetCompanyBranchBlockDates]: {
    url: mappedApis.vehicle.vehicleGroupBranchBlockDates.get.url,
  },
  [FeatureGetter.GetCompanyBranchDropoffs]: {
    url: mappedApis.base.companyBranch.dropoff.get.url,
  },
  [FeatureGetter.GetCompanyBranchOpenDays]: {
    url: mappedApis.base.companyBranch.opendays.get.url,
  },
  [FeatureGetter.GetCompanyBranchPickups]: {
    url: mappedApis.base.companyBranch.pickup.get.url,
  },
  [FeatureGetter.GetCompanyCalendar]: {
    defaultValue: [],
    url: mappedApis.base.company.calendar.get.url,
  },
  [FeatureGetter.GetCompanyCities]: {
    url: mappedApis.base.company.myCities.get.url,
  },
  [FeatureGetter.GetCompanyClients]: {
    url: mappedApis.base.company.clients.get.url,
  },
  [FeatureGetter.GetCompanyDashboard]: {
    defaultValue: [],
    url: mappedApis.base.company.dashboard.get.url,
  },
  [FeatureGetter.GetCompanyFinance]: {
    url: mappedApis.base.company.finance.get.url,
  },
  [FeatureGetter.GetCompanySearch]: {
    url: mappedApis.base.company.search.get.url,
  },
  [FeatureGetter.GetCompanyVehicleCalendar]: {
    url: mappedApis.base.company.calendar.get.url,
  },
  [FeatureGetter.GetDiscount]: {
    url: mappedApis.discount.list.url,
  },
  [FeatureGetter.GetDiscountUsage]: {
    url: mappedApis.discount.usage.url,
  },
  [FeatureGetter.GetIpClient]: {
    url: mappedApis.partner.ip.get.url,
  },
  [FeatureGetter.GetPlaces]: {
    url: mappedApis.base.places.places.get.url,
  },
  [FeatureGetter.GetPlacesPickupDropoff]: {
    url: mappedApis.base.places.pickupdropoff.get.url,
  },
  [FeatureGetter.GetUser]: {
    url: mappedApis.base.account.account.get.url,
  },
  [FeatureGetter.GetUserCreditCard]: {
    url: mappedApis.base.creditcard.creditcard.get.url,
  },
  [FeatureGetter.GetVehicle]: {
    url: mappedApis.vehicle.vehicle.get.url,
  },
  [FeatureGetter.GetVehicleAllDetail]: {
    url: mappedApis.vehicle.all.get.url,
  },
  [FeatureGetter.GetVehicleAvailable]: {
    url: mappedApis.vehicle.available.get.url,
  },
  [FeatureGetter.GetVehicleCalendar]: {
    url: mappedApis.vehicle.calendar.get.url,
  },
  [FeatureGetter.GetVehicleGroup]: {
    keyText: "vehicleGroup",
    keyValue: "uCode",
    url: mappedApis.vehicle.vehicleGroup.get.url,
  },
  [FeatureGetter.GetVehicleGroupAvailability]: {
    url: "/v1/vehicle/group/availability/{uCode}", // TODO: fix;
  },
  [FeatureGetter.GetVehicleGroupBlockDates]: {
    url: mappedApis.vehicle.vehicleGroupBlockDates.get.url,
  },
  [FeatureGetter.GetVehicleGroupBookingAdditional]: {
    url: mappedApis.vehicle.vehicleGroupBookingAdditional.get.url,
  },
  [FeatureGetter.GetVehicleGroupBookingAdditionalLinking]: {
    url: mappedApis.vehicle.vehicleGroupBookingAdditionalLinking.get.url,
  },
  [FeatureGetter.GetVehicleGroupPrice]: {
    url: mappedApis.vehicle.vehicleGroupPrice.get.url,
  },
  [FeatureGetter.GetVehicleGroupPriceDates]: {
    url: mappedApis.vehicle.vehicleGroupPriceDates.get.url,
  },
  [FeatureGetter.GetVehicleGroupStock]: {
    url: mappedApis.vehicle.vehicleGroupStock.get.url,
  },
  [FeatureGetter.GetVehicleGroupType]: {
    url: mappedApis.vehicle.vehicleGroupType.get.url,
  },
  [FeatureGetter.GetVehicleProtectionAndEquipment]: {
    url: mappedApis.vehicle.vehicleGroupProtectionAndEquipment.get.url,
  },
  [FeatureGetter.GetVehicleStoreAvailable]: {
    url: mappedApis.vehicle.availableBranch.get.url,
  },
}

export const getData =
  ({
    feature,
    options = { withCleanData: true },
    pathParams = {},
    queryParams = {},
    signal,
  }: {
    feature: FeatureGetter
    queryParams?: any
    pathParams?: any
    signal: AbortSignal
    options?: { withCleanData: boolean }
  }) =>
  async (dispatch: Dispatch) => {
    const dataFeature = features[feature]

    try {
      const url = dataFeature.url

      const keySignal = `${feature}__${slugify(Utils.cleanString(JSON.stringify(pathParams)))}__${slugify(Utils.cleanString(JSON.stringify(queryParams)))}`
      const previousController = signalRequests[keySignal]
      let currentSignal = signal

      if (!signal) {
        if (previousController) {
          previousController?.abort()
        }

        const abortController = new AbortController()
        signalRequests[keySignal] = abortController
        currentSignal = abortController.signal
      }

      if (options.withCleanData) {
        const defaultValue = dataFeature?.defaultValue || []
        GetterEffect.setData(dispatch, { data: defaultValue, feature })
      }

      GetterEffect.setViewState(dispatch, {
        feature,
        viewState: StateMachineType.Loading,
      })

      const flatQueryParams =
        Object.keys(queryParams).reduce(
          (result: string | null, key: string) => {
            if (result) {
              return `${result}&${key}=${queryParams[key]}`
            } else {
              return `?${key}=${queryParams[key]}`
            }
          },
          null,
        ) || ""

      const urlWithPathParams =
        Object.keys(pathParams).reduce((result: string, key: string) => {
          return result.replace(`{${key}}`, pathParams[key])
        }, url) || url

      const result = await GetterEffect.getData({
        signal: currentSignal,
        url: `${urlWithPathParams}${flatQueryParams}`,
      })

      if (result.hasError) {
        GetterEffect.setData(dispatch, { data: result.data, feature })
        GetterEffect.setViewState(dispatch, {
          feature,
          viewState: StateMachineType.Error,
        })
      } else {
        GetterEffect.setData(dispatch, { data: result.data, feature })
        GetterEffect.setViewState(dispatch, {
          feature,
          viewState: StateMachineType.Loaded,
        })
      }

      return result.data
    } catch (e) {
      if (e instanceof Error && e.name === "AbortError") {
        return
      }

      const defaultValue = dataFeature?.defaultValue || []
      GetterEffect.setData(dispatch, { data: defaultValue, feature })

      GetterEffect.setViewState(dispatch, {
        feature,
        viewState: StateMachineType.Error,
      })
    }
  }

export const cleanData =
  ({ feature }: { feature: FeatureGetter }) =>
  async (dispatch: Dispatch) => {
    const dataFeature = features[feature]

    const defaultValue = dataFeature?.defaultValue || []
    GetterEffect.setData(dispatch, { data: defaultValue, feature })

    GetterEffect.setViewState(dispatch, {
      feature,
      viewState: StateMachineType.NotStarted,
    })
  }

import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { Form, Skeleton } from "antd"
import { logEvent } from "firebase/analytics"
import { analytics } from "libs/firebase"
import moment from "moment"

import { Text } from "@/components/atom"
import { FormDatePicker } from "@/components/molecule"
import EmptyVehicle from "@/components/molecule/empty/vehicle"
import FormTime from "@/components/molecule/form_time"
import SkeletonList from "@/components/molecule/skeleton_list"
import Footer from "@/components/organism/footer"
import Header from "@/components/organism/header"
import ManageDate from "@/libs/date"
import StateMachine from "@/libs/statemachine"
import ManageUrl from "@/libs/url"
import { useChecktokenStore } from "@/store/checktoken/useChecktokenStore"
import { FeatureGetter } from "@/store/getter/Action"
import { useGetterStore } from "@/store/getter/Hooks"

import CardGroupVehicle from "../UserSearchVehicleAvailable/components/cardGroup"
import { VehicleSearched } from "../UserSearchVehicleAvailable/types"

const UserRentStore = () => {
  const getInitialDates = ({
    formated = false,
  }: { formated?: boolean } = {}) => {
    const pickupDate = formated
      ? ManageDate.addDays(undefined, 2, "YYYY-MM-DD")
      : ManageDate.nowAddDays(2)
    const pickupHour = "10:00"

    const dropoffDate = formated
      ? ManageDate.addDays(undefined, 5, "YYYY-MM-DD")
      : ManageDate.nowAddDays(5)
    const dropoffHour = "10:00"

    return {
      dropoffDate,
      dropoffHour,
      pickupDate,
      pickupHour,
    }
  }

  const getInitialDatesFormated = () => {
    const initialDates = getInitialDates({ formated: true })

    const start = `${initialDates.pickupDate}T${initialDates.pickupHour}`
    const end = `${initialDates.dropoffDate}T${initialDates.dropoffHour}`

    return { end, start }
  }

  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const [form] = Form.useForm()
  const [dates, setDates] = useState<{ start?: string; end?: string }>(() =>
    getInitialDatesFormated(),
  )

  const { executeCheckToken, viewState: viewStateAuth } = useChecktokenStore()

  const {
    data: dataCompany,
    getData: getCompany,
    viewState: viewStateCompany,
  } = useGetterStore({
    feature: FeatureGetter.GetCompanySearch,
  })

  const {
    data: dataVehicleAvailable,
    getData: getVehicleAvailable,
    viewState: viewStateVehicleAvailable,
  } = useGetterStore({
    feature: FeatureGetter.GetVehicleStoreAvailable,
  })

  const loadCompanyBranchData = async () => {
    const result = await getCompany({ queryParams: { slug } })

    if (result?.key === "operation_error") {
      history.push("/")
      return
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    executeCheckToken()
    loadCompanyBranchData()
  }, [])

  useEffect(() => {
    getVehicleAvailable({
      queryParams: { end: dates?.end, slug, start: dates?.start },
    })
  }, [dates?.start, dates?.end])

  const onSelectPickupDate = (selected: any) => {
    const format = selected.format("YYYY-MM-DDTHH:mm")
    const newDate = ManageDate.addDays(format, 3)
    form.setFieldValue("dropoffDate", ManageDate.mountDate(newDate))
  }

  const onInternalSubmit = (form: any) => {
    const startDate = form.pickupDate.format("YYYY-MM-DD")
    const endDate = form.dropoffDate.format("YYYY-MM-DD")

    const start = `${startDate}T${form.pickupHour}`
    const end = `${endDate}T${form.dropoffHour}`

    setDates({ end, start })
  }

  const disabledDropoffDate = (current: any) => {
    const pickupDate = form.getFieldValue("pickupDate")
    return current && current < pickupDate
  }

  const disabledPickupDate = (current: any) => {
    return current && current < moment().endOf("day")
  }

  const onSelectVehicle = (vehicle: VehicleSearched | undefined) => {
    if (StateMachine.isError(viewStateAuth)) {
      const result = ManageUrl.mountQueryParams({
        end: dates.end,
        slug,
        start: dates.start,
      })

      history.push("/signin", {
        dataToRedirect: vehicle,
        messageToRedirect:
          "Para concluir o seu aluguel você deve logar primeiro.",
        redirect: true,
        urlToRedirect: `/pages/vehicle/store/available?${result}`,
      })
    } else {
      logEvent(analytics, `vehicle_group_type`, {
        extras: {
          branchName: vehicle?.company.companyName,
          description: vehicle?.description,
        },
        type: vehicle?.vehicleGroup,
      })

      history.push(`/pages/vehicle/rent/detail`, {
        end: dates.end,
        start: dates.start,
        vehicle,
      })
    }
  }

  return (
    <div>
      <Header />
      <div className="px-4 bg-slate-200 flex justify-center">
        <div className="max-w-[1280px] w-full">
          <div className="mt-4 mb-4 p-4 bg-white border-slate-100 border-2">
            <Form
              form={form}
              initialValues={getInitialDates()}
              name="basic"
              onFinish={onInternalSubmit}
            >
              {!StateMachine.isLoaded(viewStateCompany) ? (
                <div>
                  <Skeleton />
                </div>
              ) : (
                <div className="grid grid-cols-12">
                  {/** Search */}
                  <div className="col-span-12 md:col-span-8">
                    <div
                      className="mb-4 py-2 text-center rounded-md"
                      style={{
                        background: `${dataCompany.primaryColor}4C`,
                        color: dataCompany.primaryTextColor || "#000000D9",
                      }}
                    >
                      <span className="text-2xl font-bold">
                        {dataCompany.name} em {dataCompany.city},{" "}
                        {dataCompany.state}
                      </span>
                    </div>
                    <div className="col-span-12 md:col-span-5 grid grid-cols-12">
                      <div className="col-span-6 mr-2">
                        <Text className="block text-base">Data</Text>
                        <div className="mt-2">
                          <FormDatePicker
                            disabledDate={disabledPickupDate}
                            inputReadOnly
                            name="pickupDate"
                            onSelect={onSelectPickupDate}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-span-4">
                        <Text className="block text-base">Hora</Text>
                        <div className="mt-2">
                          <FormTime
                            allowClear={true}
                            name="pickupHour"
                            required
                            showSearch={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-5 grid grid-cols-12">
                      <div className="col-span-6 mr-2">
                        <Text className="block text-base">Data</Text>
                        <div className="mt-2">
                          <FormDatePicker
                            disabledDate={disabledDropoffDate}
                            inputReadOnly
                            name="dropoffDate"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-span-4">
                        <Text className="block text-base">Hora</Text>
                        <div className="mt-2">
                          <FormTime
                            allowClear={true}
                            name="dropoffHour"
                            required
                            showSearch={true}
                          />
                        </div>
                      </div>
                      <div className="col-span-2 ml-4 flex items-center">
                        <div
                          className="py-3 w-full cursor-pointer rounded-full text-center"
                          onClick={() => form.submit()}
                          style={{
                            background: dataCompany.primaryColor || "#6282cd",
                            color: dataCompany.primaryTextColor || "#FFFFFF",
                          }}
                        >
                          <span className="text-lg font-bold">Buscar</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/** Store */}
                  <div className="md:ml-4 col-span-12 md:col-span-4">
                    <div className="flex justify-end">
                      <div className="inline-block">
                        <img
                          className="h-[160px] rounded-md"
                          src={dataCompany.logo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          </div>

          <div>
            {StateMachine.isLoading(viewStateVehicleAvailable) ? (
              <SkeletonList key="skeleton_list" />
            ) : (
              <>
                {StateMachine.isError(viewStateVehicleAvailable) ? (
                  <EmptyVehicle key="empty_vehicle" />
                ) : (
                  <>
                    {dataVehicleAvailable?.map((vehicle: VehicleSearched) => (
                      <CardGroupVehicle
                        className="mb-4"
                        key={vehicle.uCode}
                        onClick={() => onSelectVehicle(vehicle)}
                        vehicle={vehicle}
                      />
                    ))}

                    {dataVehicleAvailable?.length <= 0 && (
                      <EmptyVehicle key="empty_vehicle" />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UserRentStore

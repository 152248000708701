import React from "react"

import { Form, Input } from "antd"
import Text from "components/atom/text"

const baseClassNames =
  "rounded-md bg-white text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400"

interface FormTextAreaProps {
  disabled?: boolean
  hidden?: boolean
  label?: string
  max?: number
  min?: number
  name: string
  required?: boolean
  rows?: number
}

const FormTextArea: React.FC<FormTextAreaProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  if (props?.min) {
    rules.push({
      message: `Este campo deve ter no mínimo ${props?.min} letras.`,
      min: props?.min,
    })
  }

  if (props?.max) {
    rules.push({
      max: props?.max,
      message: `Este campo deve ter no máximo ${props?.max} letras.`,
    })
  }

  return (
    <div className={props.hidden ? "_display-none" : ""}>
      <Text className="block text-sm/6 font-semibold text-gray-900 mb-1">
        {props.label}
        {props.required && " *"}
      </Text>

      <Form.Item hasFeedback name={props.name} rules={rules}>
        <Input.TextArea
          className={baseClassNames}
          disabled={props.disabled || false}
          hidden={props.hidden || false}
          maxLength={props?.max}
          rows={props.rows || 3}
          showCount={!!props?.max}
        />
      </Form.Item>
    </div>
  )
}

export default FormTextArea

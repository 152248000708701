import React from "react"

import { InfoCircleOutlined, CarOutlined } from "@ant-design/icons"

import { Button, Text } from "@/components/atom"
import Card from "@/components/molecule/card"
import Config from "@/libs/config"
import { useStorage } from "@/libs/storage"

import { VehicleGroupValidations } from "./cardGroupValidation"
import DescriptionVehicle from "./descriptionVehicle"

interface CardVehicleProps {
  className?: string
  key?: string
  onClick?: any
  showPrice?: boolean
  vehicle?: any
}

const CardVehicle: React.FC<CardVehicleProps> = ({
  className,
  onClick,
  showPrice = true,
  vehicle,
}) => {
  const { authorization } = useStorage()

  const dayOrHourPrice = vehicle.rentalRate.byHour
    ? vehicle.rentalRate.hourCharge.priceFormat
    : vehicle.rentalRate.dayCharge.priceFormat

  const isSelectButtonAvailable = () => {
    if (!vehicle.available) {
      return false
    }

    if (authorization === null || authorization?.role === Config.role.user) {
      return false
    }

    return true
  }

  return (
    <Card className={className}>
      {/** Tablet and Desktop version */}
      <div className="hidden md:block">
        <div className={`flex ${!vehicle.available ? "opacity-50" : ""}`}>
          <div className="flex-1">
            <div className="flex">
              <div className="w-[160px]">
                <img className="w-[160px]" src={vehicle.pictureURL} />
              </div>
              <div className="flex-1 ml-4 mr-4">
                <div className="grid grid-cols-2">
                  <div className="flex flex-col">
                    <Text className="text-xl font-bold">
                      {vehicle?.description}
                    </Text>

                    <Text className="text-base">{vehicle?.vehicleGroup}</Text>

                    <DescriptionVehicle className="mt-4" vehicle={vehicle} />
                  </div>
                  <div className="ml-4">
                    <div className="flex items-center rounded-md border-2 border-slate-100 bg-slate-50">
                      <img
                        className="h-[38px] rounded-md object-cover p-1"
                        src={vehicle.company.logo}
                      />
                      <div className="pl-2 pr-2">
                        <span className="text-xs font-semibold block">
                          Locadora
                        </span>
                        <span className="text-xs overflow-hidden text-ellipsis line-clamp-1">
                          {vehicle.company.tradingName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="mb-1 text-xs font-bold">
                    Locais de retirada
                  </div>
                  {vehicle.company.pickup.map((pickup: any) => (
                    <div
                      className="mt-1 text-xs overflow-hidden text-ellipsis line-clamp-1"
                      key={pickup.uCode}
                    >
                      <CarOutlined className="mr-2 text-slate-400" />
                      <span>{pickup.local}</span>
                    </div>
                  ))}
                </div>
                <div>
                  <InfoCircleOutlined className="mr-2 mt-4 text-xs text-slate-400" />
                  <span className="text-xs">
                    A sua reserva garante um dos carros desse grupo. Modelo
                    sujeito à disponibilidade.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {showPrice && (
            <div>
              <Button
                block
                disabled={isSelectButtonAvailable()}
                onClick={onClick}
                theme="primary"
              >
                Selecionar
              </Button>
              <div className="mt-4">
                <Text className="text-4xl font-bold">{dayOrHourPrice}</Text>
                <Text className="text-sm"> por dia</Text>
              </div>
              <div className="mt-2">
                <Text className="text-lg">
                  {vehicle.rentalRate.dayCharge.totalFormat}{" "}
                  <span className="text-sm">/ total</span>
                </Text>
              </div>
              <div className="mt-1 text-center">
                <InfoCircleOutlined className="mr-2 text-xs font-bold text-slate-400" />
                <span className="text-xs">
                  Proteções e taxas não inclusas neste valor.
                </span>
              </div>
              {vehicle?.rentalRate?.discountCharge &&
                vehicle?.rentalRate?.discountCharge.total > 0 && (
                  <div className="vehicle-discount-box">
                    Desconto de{" "}
                    <strong>
                      {vehicle.rentalRate.discountCharge.totalFormat}
                    </strong>
                  </div>
                )}
            </div>
          )}
        </div>
        {!vehicle.available && (
          <div className="mt-2">
            <VehicleGroupValidations
              companyBranch={vehicle.company}
              validations={vehicle.validations}
            />
          </div>
        )}
      </div>

      {/* Mobile Version */}
      <div className="block md:hidden">
        <Text className="text-xl font-bold text-center block">
          {vehicle?.description}
        </Text>

        <Text className="text-base text-center block">
          {vehicle?.vehicleGroup}
        </Text>

        <div className="flex justify-center">
          <img className="h-[160px]" src={vehicle.pictureURL} />
        </div>

        <DescriptionVehicle className="mt-4" vehicle={vehicle} />

        <div className="mt-4 flex items-center bg-slate-50 rounded-md border-slate-100 border-2">
          <img className="h-[32px] rounded-md" src={vehicle.company.logo} />
          <div className="ml-2">
            <span className="text-xs font-semibold block">Locadora</span>
            <span className="text-xs overflow-hidden text-ellipsis line-clamp-1">
              {vehicle.company.tradingName}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <div className="mb-1 text-xs font-bold">Locais de retirada</div>
          {vehicle.company.pickup.map((pickup: any) => (
            <div
              className="text-xs overflow-hidden text-ellipsis line-clamp-1"
              key={pickup.uCode}
            >
              <CarOutlined className="mr-2 text-slate-400" />
              <span>{pickup.local}</span>
            </div>
          ))}
        </div>

        {showPrice && (
          <div>
            <div className="mt-4 text-center">
              <Text className="text-4xl font-bold">{dayOrHourPrice}</Text>
              <Text className="text-sm"> por dia</Text>
            </div>
            <div className="mt-1 text-center">
              <Text className="text-lg">
                {vehicle.rentalRate.dayCharge.totalFormat}{" "}
                <span className="text-sm">/ total</span>
              </Text>
            </div>

            <div className="mt-4 mb-4">
              <Button
                block
                disabled={isSelectButtonAvailable()}
                onClick={onClick}
                theme="primary"
              >
                Selecionar
              </Button>
            </div>

            <div className="text-center mt-1">
              <InfoCircleOutlined className="mr-2 text-xs font-bold text-slate-400" />
              <span className="text-xs">
                Proteções e taxas não inclusas neste valor. A sua reserva
                garante um dos carros desse grupo. Modelo sujeito à
                disponibilidade.
              </span>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default CardVehicle

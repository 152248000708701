import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"

import { Form, Result } from "antd"
import Body from "components/atom/body"
import Button from "components/atom/button"
import Grid, { GridSize } from "components/atom/grid"
import Layout from "components/atom/layout"
import Text from "components/atom/text"
import FormInput from "components/molecule/form_input"
import Footer from "components/organism/footer"
import Header from "components/organism/header"
import qs from "qs"
import { AppState } from "store"
import * as SigninAction from "store/signin/Action"

import { ReactComponent as ForgotPasswordSvg } from "./assets/forgot_password.svg"
import "./styles.scss"

const UserLostPassword = ({
  changePassword,
  clean,
  error,
  loading,
  success,
}: any) => {
  const { t } = useTranslation()
  const location = useLocation()
  const params: any =
    qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

  useEffect(() => {
    return () => clean()
  }, [])

  const onSubmit = async ({ password, token }: any) => {
    await changePassword({ password, token })
  }

  return (
    <div>
      <Layout>
        <Header />
        <Body>
          <Grid className="change_password_content_grid" size={GridSize.Two}>
            <div className="change_password_content_image">
              <ForgotPasswordSvg className="change_password_image" />
            </div>

            <div className="_pt-6 _pl-8 _pr-8">
              {success ? (
                <Result
                  extra={[
                    <Link key="signin" to="/signin">
                      <Button key="console" theme="primary">
                        Ir para o login
                      </Button>
                    </Link>,
                  ]}
                  status="success"
                  subTitle="Agora você já pode acessar o sistema com a nova senha."
                  title="Pronto, sua senha foi alterada com sucesso!"
                />
              ) : (
                <>
                  <div className="_text-center _mb-6">
                    <Text className="_text-6">Trocar senha</Text>
                  </div>

                  <Form
                    initialValues={{ token: params.token }}
                    layout="vertical"
                    name="basic"
                    onFinish={onSubmit}
                  >
                    <FormInput disabled={loading} hidden={true} name="token" />

                    <FormInput
                      disabled={loading}
                      label="Digite sua nova senha"
                      min={8}
                      name="password"
                      required
                      type="password"
                    />

                    <FormInput
                      customRules={[
                        ({ getFieldValue }: any) => ({
                          validator(rule: any, value: any) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              "As duas senha devem ser iguais!",
                            )
                          },
                        }),
                      ]}
                      disabled={loading}
                      label="Confirme sua nova senha"
                      min={8}
                      name="confirmpassword"
                      required
                      type="password"
                    />

                    {error && (
                      <div className="_text-center _mb-4">
                        <Text className="_text-3 _text-color-red">
                          {" "}
                          {t(error.key)}{" "}
                        </Text>
                      </div>
                    )}

                    <Form.Item>
                      <Button
                        block
                        htmlType="submit"
                        loading={loading}
                        theme="primary"
                      >
                        Confirmar troca de senha
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </Grid>
        </Body>
        <Footer />
      </Layout>
    </div>
  )
}

const mapStateToProps = ({ signin }: AppState) => {
  return {
    error: signin?.error,
    loading: signin?.loading,
    success: signin?.success,
  }
}

const mapDispatchToProps = {
  changePassword: SigninAction.changePassword,
  clean: SigninAction.clean,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLostPassword)

import React from "react"
import { useHistory } from "react-router-dom"

import { RightOutlined, LogoutOutlined } from "@ant-design/icons"
import { Tabs } from "antd"
import Layout from "components/atom/layout"
import Text from "components/atom/text"
import CreditCard from "components/organism/creditcard"
import Footer from "components/organism/footer"
import Header from "components/organism/header"
import Storage from "libs/storage"
import AccountUserBooking from "pages/UserAccount/accountUserBooking"
import AccountUserData from "pages/UserAccount/accountUserData"

import { useAccountStore } from "@/store/account/useAccountStore"

import "./styles.scss"

const { TabPane } = Tabs

const UserAccount = () => {
  const { executeAccountLogout } = useAccountStore()

  const history = useHistory()
  const data = history.location.state as any

  const onChange = (key: any) => {
    // Rever essa implementação do logout;
    if (key === "6") {
      onLogout()
    }
  }

  const onLogout = () => {
    executeAccountLogout()

    Storage.clear()
    history.push(`/`)
  }

  return (
    <Layout>
      <Header />
      <div className="px-6 mt-6">
        <div className="text-4xl font-bold">Meu Perfil</div>

        <Tabs
          animated={true}
          className="hidden md:block"
          defaultActiveKey={data?.activeKey ? data.activeKey : "1"}
          onChange={onChange}
          size="large"
        >
          <TabPane key="1" tab="Minhas reservas">
            <AccountUserBooking />
          </TabPane>
          <TabPane key="4" tab="Meus Cartões">
            <CreditCard hideSecurityFields={true} />
          </TabPane>
          <TabPane key="5" tab="Meus Dados">
            <AccountUserData />
          </TabPane>
          <TabPane key="6" tab="Sair" />
        </Tabs>

        {/** Mobile Menu */}
        <div className="block mt-4 md:hidden">
          <div
            className="py-4 flex border-b-2 border-b-slate-200"
            onClick={() => history.push("/account/user/mobile/book")}
          >
            <Text className="flex-1 text-lg">Minhas reservas</Text>
            <RightOutlined />
          </div>

          <div
            className="py-4 flex border-b-2 border-b-slate-200"
            onClick={() => history.push("/account/user/mobile/creditcard")}
          >
            <Text className="flex-1 text-lg">Meus cartões</Text>
            <RightOutlined />
          </div>

          <div
            className="py-4 flex border-b-2 border-b-slate-200"
            onClick={() => history.push("/account/user/mobile/data")}
          >
            <Text className="flex-1 text-lg">Meus dados</Text>
            <RightOutlined />
          </div>

          <div className="py-4 flex" onClick={onLogout}>
            <Text className="flex-1 text-lg">Sair</Text>
            <LogoutOutlined />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default UserAccount

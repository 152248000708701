import React from "react"

import { Form, Checkbox } from "antd"

interface FormCheckboxProps {
  label: any
  name: string
  required?: boolean
  requiredMessage?: string
}

const FormCheckbox: React.FC<FormCheckboxProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({
      validator: (_: any, value: boolean) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                props.requiredMessage || "Este campo não pode ser vazio.",
              ),
            ),
    })
  }

  return (
    <div>
      <Form.Item name={props.name} rules={rules} valuePropName="checked">
        <Checkbox>{props.label}</Checkbox>
      </Form.Item>
    </div>
  )
}

export default FormCheckbox

const riskReasonDefault =
  "Agradecemos pelo seu interesse. No entanto, nossos critérios de análise indicaram que a aprovação não é possível neste momento. Sugerimos aguardar um período antes de realizar uma nova tentativa, caso deseje informações adicionais entre em contato com nosso suporte."

export const pt = {
  translation: {
    account_already_exist: "Já existe uma conta para esse email.",
    account_created_error: "Ops, parece que há algo faltando em seu cadastro.",
    account_not_authorized:
      "Ops! Você não está autorizado ou houve algum erro na solicitação.",
    booking_error_account_not_completed:
      "Você deve completar todos os dados de cadastro antes de efetuar uma reserva",
    booking_error_account_not_upload_driver_license:
      "Você deve enviar um foto da sua carteira de motorista. Arquivo de ser em PNG ou PDF.",
    booking_error_charge:
      "Ops, houve um problema ao buscar os dados. Por favor tente novamente.",
    booking_error_cnh_expired:
      "A sua carteira de motorista está fora da validade. Faça um novo upload no seu perfil para continuar.",
    booking_error_cnh_front_back_not_match:
      "A frente ou o verso da sua carteira de motorista não são validos.",
    booking_error_cnh_not_valid:
      "A foto da sua CNH não é válida, isso pode ocorrer por conta da qualidade da image. Faça um novo upload.",
    booking_error_cnh_photo_not_found:
      "Faça o upload da foto da sua carteira de motorista.",
    booking_error_cpf: "O CPF informado é inválido.",
    booking_error_cpf_already_regitered:
      "Este CPF já está cadastrado na nossa base.",
    booking_error_cpf_has_restrictions:
      "Infelizmente o seu CPF possui restrições, não poderemos prosseguir com a sua reserva.",
    booking_error_cpf_invalid: "Este CPF não é válido.",
    booking_error_cupom_code_invalid: "Ops! Este cupom não é valido.",
    booking_error_cvv: "Os dados do cartão estão incorretos.",
    booking_error_data_not_match:
      "Os dados informados não estão corretos. Por favor valide seus dados.",
    booking_error_dropoff_invalid:
      "Você selecionou um local de devolução inválido.",
    booking_error_email_already_regitered:
      "O email já existe no nosso banco de dados.",
    booking_error_email_not_valid: "O email de cadastro não é válido.",
    booking_error_exceeded_future_booking_limit:
      "Não é permitido locação em uma data tão distante. Escolha uma data mais próxima.",
    booking_error_exceeded_maximum_booking_days:
      "Não é permitido locação para esse total de dias. Reduza a tempo da locação.",
    booking_error_generic:
      "Ops, houve um problema ao criar sua reserva. Por favor tente novamente.",
    booking_error_insurance_validate_error:
      "Ops! Esse veículo não pode ser validado junto ao nosso seguro.",
    booking_error_missing_basic_information:
      "Você deve preencher as informações básicas. Nome, Telefone, Data de nascimento e CPF.",
    booking_error_missing_driver_address_data:
      "Alguns dados do endereço estão faltando.",
    booking_error_missing_driver_address_proof:
      "Faça o upload da foto do comprovante de residência.",
    booking_error_missing_driver_license_proof:
      "Faça o upload de uma imagem da carteira de motorista válida.",
    booking_error_not_available_for_pickup:
      "Não está disponível para essa data de retirada",
    booking_error_not_available_start_date:
      "A locadora não está aberta na data de retirada.",
    booking_error_not_open_end_date:
      "A locadora não está aberta na data de devolução.",
    booking_error_not_open_start_date:
      "A locadora não está aberta na data de devolução.",
    booking_error_pickup_invalid:
      "Você selecionou um local de retirada inválida.",
    change: "Trocar",
    change_date_mobile: "Clique para alterar",
    change_date_mobile_title: "Alterar datas",
    charges: "Pagamento",
    choose_mobile_pickup: "Data e hora de Retirada",
    choose_mobile_return: "Data e hora de Devolução",
    choose_pickup: "Data de Retirada",
    choose_place: "Lugar de retirada",
    choose_return: "Data de Devolução",
    choose_time_pickup: "Hora de Retirada",
    choose_time_return: "Hora de Devolução",
    chosen_date: "Data e hora de retirada e devolução",
    city: "Cidade",
    close: "Fechar",
    company_branch_error_airport_registered:
      "Este aeroporto já está cadastrado.",
    company_not_approved:
      "A sua empresa ainda está em fase aprovação, aguarde até o processo de validação ser finalizado.",
    confirm: "Confirmar",
    cupom_error_invalid: "Ops! Este cupom não é valido.",
    customer_did_not_meet_risk_criteria: riskReasonDefault,
    date_before_start_allowed: "Data anterior a data de início permitida.",
    date_blocked_by_branch: "Data indisponível para a unidade.",
    date_blocked_by_group: "Data indisponível para o grupo de veículos.",
    date_branch_not_working: "Não há funcionamento neste dia.",
    date_not_valid: "Essa data não é válida",
    discount: "Desconto ( {discount}% )",
    drop_off: "Devolução",
    dropoff_place: "Local de devolução",
    favorite: "Favoritar",
    favorited: "Favoritado",
    friday: "Sexta-feira",
    generic_error:
      "Ops! Acorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
    go_my_reservation: "Ir para minhas reservas",
    go_website: "Ir para o site",
    insurance_price: "Preço do seguro",
    login: "Fazer Login",
    manual_select_the_date:
      "Selecione a data e hora de inicio e fim da reserva manual",
    monday: "Segunda-feira",
    my_account: "Minha Conta",
    open_day_friday: "Sexta-feira",
    open_day_monday: "Segunda-feira",
    open_day_saturday: "Sábado",
    open_day_sunday: "Domingo",
    open_day_thursday: "Quinta-feira",
    open_day_tuesday: "Terça-feira",
    open_day_wednesday: "Quarta-feira",
    operation_error_account_exist: "Este email já possui uma conta.",
    operation_error_cpf_invalid: "Este CPF é inválido.",
    operation_error_cpf_not_valid: "Este CPF não é válido",
    operation_error_date_already_blocked: "Esta data já está cadastrada.",
    operation_error_date_price_already_blocked: "Esta data já está cadastrada.",
    operation_error_end_greater_than_start:
      "Data final menor que a data inicial.",
    operation_error_more_then_7_days:
      "A faixa de datas pode ser no máximo 7 dias.",
    operation_error_start_before_today: "Data de início anterior a hoje.",
    payment_not_authorized: "Ops, não foi possível aprovar o pagamento.",
    pick_up: "Retirada",
    pickup_place: "Local de retirada",
    place: "Locadora",
    price_per_day: "Preço por dia",
    price_per_hour: "Preço por hora",
    quote_forbidden: "A cotação não pode ser criada com uma conta empresarial.",
    register_already_exist: "Este registro já existe.",
    rent: "Alugar",
    rent_done_info:
      "Pronto! Seu aluguel foi feito com sucesso! Agora é só ir até a loja e buscar o veículo na data e hora solicitados.",
    rent_error_protection: "Você deve selecionar pelo menos 1 proteção.",
    reservation_code: "Código da sua reserva",
    reservation_code_movida: "Leve esse código na hora da sua reserva.",
    reserver_near_you: "Reserve os melhores veículos próximos a você.",
    risk_reason_anti_fraud_score_did_not_meet_criteria: riskReasonDefault,
    risk_reason_anti_fraud_score_failed: riskReasonDefault,
    risk_reason_basic_data_insuficient: riskReasonDefault,
    risk_reason_cnh_expired:
      "A sua carteira de motorista está fora da validade. Faça um novo upload.",
    risk_reason_cnh_failed:
      "Parece que seu carteira está vencida ou não conseguimos validar o documento. Faça um novo upload.",
    risk_reason_cnh_front_back_not_match:
      "A frente ou o verso da sua carteira de motorista não são validos. Faça um novo upload.",
    risk_reason_cnh_not_valid:
      "A foto da sua CNH não é válida, isso pode ocorrer por conta da qualidade da image. Faça um novo upload.",
    risk_reason_cnh_photo_not_found:
      "Faça o upload da foto da sua carteira de motorista.",
    risk_reason_collections_failed: riskReasonDefault,
    risk_reason_cpf_not_valid: "Este CPF não é valido.",
    risk_reason_cpf_or_birthdate_invalid:
      "O seu CPF ou data de nascimento estão inválidos",
    risk_reason_criminal_record_failed: riskReasonDefault,
    risk_reason_default_score_did_not_meet_criteria: riskReasonDefault,
    risk_reason_default_score_failed: riskReasonDefault,
    risk_reason_email_failed: "Este email não é válido",
    risk_reason_email_not_valid: "Este email não é válido",
    risk_reason_financial_risk_failed: riskReasonDefault,
    risk_reason_kyc_compliance_failed: riskReasonDefault,
    risk_reason_risk_score_phone_did_not_meet_criteria: riskReasonDefault,
    risk_reason_social_assistance_included: riskReasonDefault,
    saturday: "Sábado",
    search: "Procurar",
    see_on_map: "Ver no mapa",
    see_that_store: "Ver carros",
    select_the_date: "Seleciona a data e hora de retirada e devolução",
    sunday: "Domingo",
    tax_administration_price: "Taxa de admin.",
    taxes_fees: "Impostos e Taxas",
    term_of_use_must_be_accepted: "Você precisar aceitar os termos de uso.",
    thursday: "Quinta-feira",
    total_days:
      "{day, plural, =0 {Preço final} =1 {Preço por 1 dia} other {Preço por # dias}}",
    total_hours:
      "{hour, plural, =0 {Preço final} =1 {Preço por 1 hora} other {Preço por # horas}}",
    total_price: "Preço Total",
    ts_company_question_car_condition:
      "O cliente entregou o veículo com as codições acordadas?",
    ts_company_question_dropoff_on_time:
      "O cliente entregou o veículo na hora e data reservada?",
    ts_company_question_user_rating: "Qual a avaliação do cliente?",
    ts_key_airports: "Aeroportos",
    ts_key_cities: "Cidades",
    ts_vehicle_automatic: "Automático",
    ts_vehicle_manual: "Manual",
    tuesday: "Terça-feira",
    type_name_city: "digite o nome da cidade",
    validationerror_acceptTerms: "Você deve aceitar os termos de uso",
    validationerror_cnhExpirationDate:
      "Você deve informar a data de validade da CNH",
    validationerror_cnhNumber: "Você deve informar o número da CNH",
    validationerror_code:
      "Para o código do cupom só permitido letras, números, hífen (-) e underline (_). Não é permitido espaços.",
    validationerror_cpf: "Este CPF não é válido",
    validationerror_description: "Você deve incluir uma descrição.",
    validationerror_doors: "Você deve informar o número de portas",
    validationerror_email: "Você deve informar o email",
    validationerror_facebook: "Você deve incluir http:// ou https://",
    validationerror_image:
      "Você deve selecionar ou fazer o upload de uma imagem",
    validationerror_instagram: "Você deve incluir http:// ou https://",
    validationerror_linkedin: "Você deve incluir http:// ou https://",
    validationerror_manufactureYear: "Ano de fabricação inválido",
    validationerror_modelYear: "Ano de modelo inválido",
    validationerror_name: "Você deve informar o nome",
    validationerror_neighbor: "Você deve incluir o bairro no endereço.",
    validationerror_password: "Você deve escolher uma senha",
    validationerror_phone: "Você deve informar o telefone",
    validationerror_primaryColor:
      "A cor deve ser em Hexadecimal. Exemplo: #000000",
    validationerror_primaryTextColor:
      "A cor deve ser em Hexadecimal. Exemplo: #000000",
    validationerror_protections: "Você deve selecionar pelo menos 1 proteção",
    validationerror_seats: "Você deve informar o número de lugares",
    validationerror_site: "Você deve incluir http:// ou https://",
    validationerror_vehicleUpload: "O veículo deve ter pelo menos 1 foto.",
    vehicle_already_has_booking: "Este veículo já está alugado nesta data.",
    vehicle_group_already_registered: "Grupo de veículo já cadastrada.",
    vehicle_group_not_available:
      "Não há veículos disponíveis no grupo para essa data.",
    vehicle_in_all_places: "Veículos em qualquer lugar do Brasil",
    wednesday: "Quarta-feira",
  },
}

export const DEFAULT_ERROR_MESSAGE = "account_not_authorized"
export const GENERIC_ERROR_MESSAGE = "generic_error"

import React, { useState, useEffect } from "react"

import {
  CompassOutlined,
  CheckCircleOutlined,
  StarOutlined,
} from "@ant-design/icons"
import { Drawer } from "antd"
import Button from "components/atom/button"
import Content from "components/atom/content"
import Grid, { GridSize } from "components/atom/grid"
import Search from "components/molecule/search"
import Footer from "components/organism/footer"
import FooterRequirements from "components/organism/footer_requirements"
import Storage from "libs/storage"

import "./styles.scss"

const Landpage: React.FC<any> = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!Storage.hasCookieAccept()) {
      setVisible(true)
    }
  }, [])

  const onCloseDrawer = () => {
    setVisible(false)
  }

  const onSaveCookies = (accept: boolean) => {
    Storage.cookie(accept)
    setVisible(false)
  }

  return (
    <div>
      <div>
        <Search />

        <div className="_mt-8">
          <div className="text-center">
            <span className="block text-3xl">
              Passos simples e fáceis para alugar
            </span>
            <span className="block mt-4 text-xl">
              Aqui você vai alugar com tranquilidade e segurança.
            </span>
          </div>

          <Grid
            className="_mt-8 _mb-8 landing_tips_content"
            size={GridSize.Three}
          >
            <div className="landing_path">
              <CompassOutlined className="landing_path_image" />
              <div className="landing_path_title">Escolha a cidade</div>
              <div className="landing_path_subtitle">
                Busque pela cidade que você deseja.
              </div>
            </div>

            <div className="landing_path">
              <CheckCircleOutlined className="landing_path_image" />
              <div className="landing_path_title">
                Escolha o grupo de veículo
              </div>
              <div className="landing_path_subtitle">
                Temos várias marcas e modelos escolha o que se adequa a você e
                sua necessidade.
              </div>
            </div>

            <div className="landing_path">
              <StarOutlined className="landing_path_image" />
              <div className="landing_path_title">Proteções e opcionais</div>
              <div className="landing_path_subtitle">
                Tenha conveniência e flexibilidade na suas escolhas, temos
                várias opções para melhorar sua experiência.
              </div>
            </div>
          </Grid>
        </div>

        <div className="landing_divisor_content">
          <div className="landing_divisor" />
        </div>

        <Content className="_mb-4">
          {/* <div className="landing_title_section _text-center">
            Conheça algumas cidade terão nossos serviços.
          </div> */}

          {/* <Grid size={GridSize.Three} className="_mt-8 _mb-8 landing_city_content">
            <div className="landing_city_box">
              <ImageLoad url="https://53cars.s3-sa-east-1.amazonaws.com/beloHorizonte.jpg" className="landing_city_box_image" />
              <div className="landing_city_box_title"> Belo Horizonte </div>
            </div>

            <div className="landing_city_box">
              <ImageLoad url="https://53cars.s3-sa-east-1.amazonaws.com/saoPaulo.jpg" className="landing_city_box_image" />
              <div className="landing_city_box_title"> São Paulo </div>
            </div>

            <div className="landing_city_box">
              <ImageLoad url="https://53cars.s3-sa-east-1.amazonaws.com/rioDeJaneiro.jpg" className="landing_city_box_image" />
              <div className="landing_city_box_title"> Rio de Janeiro </div>
            </div>
          </Grid> */}

          <div className="landing_title_section _text-center _mb-6">
            Estamos no Brasil todo, faça sua reserva!
          </div>
        </Content>

        <FooterRequirements />
      </div>

      <Footer />

      <Drawer
        closable={false}
        onClose={onCloseDrawer}
        open={visible}
        placement={"bottom"}
        title="Nossa site utiliza cookies"
      >
        <p>
          Para obter mais informações sobre isso, leia nossa Política de
          Privacidade.
        </p>
        <p>
          <a
            href="https://closecar-public.s3.amazonaws.com/Termos+de+Uso+-+Usuario+e+CloseCar.pdf"
            rel="noreferrer"
            target="_blank"
          >
            Nossa política de privacidade
          </a>
        </p>
        <Button onClick={() => onSaveCookies(false)} theme="outline-black">
          Rejeitar
        </Button>
        <Button
          className="_ml-4"
          onClick={() => onSaveCookies(true)}
          theme="primary"
        >
          Aceitar e melhorar minha experiência
        </Button>
      </Drawer>
    </div>
  )
}

export default Landpage

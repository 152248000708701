import React, { useEffect } from "react"

import { Form, Result } from "antd"

import { Button } from "@/components/atom"
import FormInput, { InputMask } from "@/components/molecule/form_input"
import Footer from "@/components/organism/footer"
import Header from "@/components/organism/header"
import StateMachine from "@/libs/statemachine"
import { FeatureExecuter } from "@/store/executer/Action"
import { useExecuterStore } from "@/store/executer/Hooks"

const LandpageComercial = () => {
  const [form] = Form.useForm()

  const {
    clean: clean,
    executeAction: getContact,
    viewState: viewState,
  } = useExecuterStore({
    feature: FeatureExecuter.CompanyContact,
  })

  useEffect(() => {
    return () => {
      clean()
    }
  }, [])

  const onSubmit = (form: any) => {
    console.log(form)
    getContact({ bodyParams: form })
  }

  return (
    <div>
      {/** HEADER */}
      <div className="absolute inset-x-0 top-0 z-50">
        <Header withLogo={true} withMenu={false} />
      </div>

      {/** TOP CONTENT */}
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#F7E3AF] to-[#FAA613] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
        </div>
        <div className="mx-auto max-w-2xl py-16 sm:py-24">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Venha conhecer todos os nosso benefícios
              <a
                className="pl-2 font-semibold text-indigo-600"
                href="#features"
              >
                <span aria-hidden="true" className="absolute inset-0"></span>
                Saber mais <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
              Gestão de reservas mais segura e rápida.
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              Análises integrada contra fraude, crédito e riscos usando as
              técnologias mais avançadas como machine learning e inteligência
              artifical. Conheça melhor o seu cliente antes de alugar.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                href="#contact"
              >
                Experimente já
              </a>
              <a
                className="text-sm/6 font-semibold text-gray-900"
                href="#features"
              >
                Saiba mais <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#F7E3AF] to-[#FAA613] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
        </div>
      </div>

      {/** OUR WAY TO WORK */}
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <div
          aria-hidden="true"
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        >
          <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"></div>
        </div>
        <div
          aria-hidden="true"
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        >
          <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"></div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto">
            <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-7xl">
              Nosso jeito de trabalhar
            </h2>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8">
              Com o nosso sistema não há cobrança de taxa para instação, nenhuma
              taxa fixa mensal e sem adesão mínima. Nós trabalhamos sempre em
              parceria com nossos clientes, e seguimos o modelo ganha-ganha. E
              como a CloseCar ganha? É cobrado uma porcentagem sobre a reserva
              efetivada na plataforma, assim o cliente ganhando nós ganhamos
              juntos.
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base/7 font-semibold text-white sm:grid-cols-2 md:flex lg:gap-x-10">
              <span>
                Sem adesão <span aria-hidden="true">&rarr;</span>
              </span>
              <span>
                R$ 0 de instalação <span aria-hidden="true">&rarr;</span>
              </span>
              <span>
                R$ 0 fixo mensal <span aria-hidden="true">&rarr;</span>
              </span>
              <span>Porcentagem na efetivação da reserva! </span>
            </div>
          </div>
        </div>
      </div>

      {/** FEATURES */}
      <div className="bg-white py-24" id="features">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base/7 font-semibold text-indigo-600">
              Mais seguraça e agilidade
            </h2>
            <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
              Tudo que você precisa para sua gestão de reservas.
            </p>
            <p className="mt-6 text-lg/8 text-gray-600">
              Fique livre do excel! Com o nosso sistema você terá na palma da
              sua mão toda a gestão das suas reservas. Com integrações que dão
              mais segurança e agilidade você poderá gerir suas reserva e contar
              com um dos sistemas de anàlise de cliente mais avançados.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              <div className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg
                      aria-hidden="true"
                      className="size-6 text-white"
                      data-slot="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  Sistema na nuvem
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  Sistema web na nuvem sem custos fixos e necessidade de
                  instalação por parte da locadora.
                </dd>
              </div>
              <div className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg
                      aria-hidden="true"
                      className="size-6 text-white"
                      data-slot="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  100% Seguro
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  Todos os dados em nosso sistema são criptografados e seguros.
                </dd>
              </div>
              <div className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg
                      aria-hidden="true"
                      className="size-6 text-white"
                      data-slot="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  Simples integração
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  É simples começar a usar o sistema, sem custos de instalação e
                  tudo feito direto na nuvem. Nosso sistema é configurável,
                  flexível e você poderá gerenciar tudo online e rápido.
                </dd>
              </div>
              <div className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg
                      aria-hidden="true"
                      className="size-6 text-white"
                      data-slot="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  Análises avançadas
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  Com parcerias com as maiores soluções para validação de
                  clientes da América Latina, temos um modelo avançado de
                  análise de fraude, crédito e risco.
                </dd>
              </div>
              <div className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg
                      aria-hidden="true"
                      className="size-6 text-white"
                      data-slot="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  Prenseça online
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  Aumente o seu alcance estando junto a nossa plataforma onde
                  nosso usuários poderam fazer reservas online direto para sua
                  locadora.
                </dd>
              </div>
              <div className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg
                      aria-hidden="true"
                      className="size-6 text-white"
                      data-slot="icon"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  Loja exclusiva
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  Tenha uma loja exclusiva dentro do nosso site, aonde você
                  poderá compartilhar com seus cliente e eles poderam fazer
                  reserva online direto para sua locadora.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="flex justify-center mb-16 mt-8">
        <div className="bg-[#392543] text-center rounded-full px-4 sm:px-8 py-1 text-sm/6 text-white font-bold shadow-md">
          Deseja conhecer mais? Fale com a gente!
        </div>
      </div>

      {/** BUSSINESS CONTACT */}
      <div className="isolate bg-white px-6 pb-16" id="contact">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Entre em contato
          </h2>
          <p className="mt-4 text-lg/8 text-gray-600">
            Um de nossos especialistas entrará em contato com você para
            apresentação de nosso sistema e benefícios.
          </p>
        </div>

        {StateMachine.isLoaded(viewState) ? (
          <Result
            status="success"
            subTitle="Pronto, agora em até 1 dia útil um de nossos especialista irá entrar em contato com você."
            title="Contato enviado com sucesso!"
          />
        ) : (
          <Form
            className="mt-8 mx-auto max-w-xl"
            form={form}
            onFinish={onSubmit}
          >
            <div className="grid grid-cols-12 md:gap-4">
              <div className="col-span-12">
                <FormInput
                  className="mb-2 md:mb-4"
                  label="Nome"
                  loading={StateMachine.isLoading(viewState)}
                  name="name"
                  required
                />
              </div>
              <div className="col-span-12">
                <FormInput
                  className="mb-2 md:mb-4"
                  label="Empresa"
                  loading={StateMachine.isLoading(viewState)}
                  name="companyName"
                  required
                />
              </div>
              <div className="col-span-12">
                <FormInput
                  className="mb-2 md:mb-4"
                  label="Email"
                  loading={StateMachine.isLoading(viewState)}
                  name="email"
                  required
                />
              </div>
              <div className="col-span-12">
                <FormInput
                  className="mb-2 md:mb-4"
                  label="Telefone"
                  loading={StateMachine.isLoading(viewState)}
                  mask={InputMask.DynamicPhone}
                  name="phone"
                  required
                />
              </div>
              <div className="mt-4 col-span-12">
                <Button
                  block
                  className="bg-indigo-600 border-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  htmlType="submit"
                  loading={StateMachine.isLoading(viewState)}
                  theme="primary"
                >
                  Enviar contato
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>

      <Footer />
    </div>
  )
}

export default LandpageComercial

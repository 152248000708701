import { Dispatch } from "redux"

import * as SignupEffect from "./Effect"

export interface SignupParams {
  acceptTerms: boolean | number
  cpf: string
  email: string
  name: string
  password: string
}

export interface SignupCompanyParams {
  acceptTerms: boolean | number
  documentLegalNumber: string
  email: string
  name: string
  password: string
  phone: string
  tradingName: string
}

export const signup =
  ({ acceptTerms, cpf, email, name, password }: SignupParams) =>
  async (dispatch: Dispatch) => {
    SignupEffect.setLoading(dispatch, true)
    const authorization = await SignupEffect.signup({
      acceptTerms,
      cpf,
      email,
      name,
      password,
    })

    if (authorization.hasError) {
      SignupEffect.setError(dispatch, authorization.data)
    } else {
      SignupEffect.setSuccess(dispatch, true)
    }

    SignupEffect.setLoading(dispatch, false)
  }

export const signupCompany =
  ({
    acceptTerms,
    documentLegalNumber,
    email,
    name,
    password,
    phone,
    tradingName,
  }: SignupCompanyParams) =>
  async (dispatch: Dispatch) => {
    SignupEffect.setLoading(dispatch, true)
    const authorization = await SignupEffect.signupCompany({
      acceptTerms,
      documentLegalNumber,
      email,
      name,
      password,
      phone,
      tradingName,
    })

    if (authorization.hasError) {
      SignupEffect.setError(dispatch, authorization.data)
    } else {
      SignupEffect.setSuccess(dispatch, true)
    }

    SignupEffect.setLoading(dispatch, false)
  }

export const clean = () => async (dispatch: Dispatch) => {
  SignupEffect.setLoading(dispatch, false)
  SignupEffect.setError(dispatch, null)
  SignupEffect.setSuccess(dispatch, false)
}

import React from "react"
import { useTranslation } from "react-i18next"

import { WarningOutlined } from "@ant-design/icons"

type Props = {
  validations: {
    openForEndDate: {
      closeHour: string
      day: string
      isOpen: boolean
      openHour: string
    }
    openForStartDate: {
      closeHour: string
      day: string
      isOpen: boolean
      openHour: string
    }
    exceedLimitFutureBooking: boolean
    exceedMaximunBookingDays: boolean
    isAvailableForSelectedStartDate: boolean
    lastDateAvailableToBook: string
    minAvailableDateToBook: string
    isPickupDropoffAcceptable: boolean
    isRequestedDatesBlockedByPickup: boolean
    isRequestedDatesBlockedByBranch: boolean
    pickupDateBlocked: {
      date: string
      dateFormat: string
    }
    branchDateBlocked: {
      date: string
      dateFormat: string
    }[]
  }
  companyBranch: {
    limitEndBookingDays: number
    maxBookingDays: number
  }
}

export const VehicleGroupValidations: React.FC<Props> = ({
  companyBranch,
  validations,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-2">
      {!validations?.isAvailableForSelectedStartDate && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>Este grupo não está disponível nessa data.</span>
            {validations?.minAvailableDateToBook && (
              <>
                <span> Faça uma nova busca apartir&nbsp;</span>
                <span className="underline underline-offset-2">
                  {validations?.minAvailableDateToBook}.
                </span>
              </>
            )}
          </span>
        </div>
      )}
      {!validations?.isPickupDropoffAcceptable && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>
              Este grupo não aceita retirada e devolução em locais diferentes.
              Faça uma nova busca com os mesmos locais.
            </span>
          </span>
        </div>
      )}
      {validations?.isRequestedDatesBlockedByPickup && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>
              Este grupo não está disponível nas datas&nbsp;
              {validations?.pickupDateBlocked.dateFormat}.
            </span>
          </span>
        </div>
      )}
      {validations?.isRequestedDatesBlockedByBranch && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>
              A unidade não funcionará nas datas&nbsp;
              {validations?.branchDateBlocked
                ?.map((dates) => dates.dateFormat)
                ?.join(", ")}
              .
            </span>
          </span>
        </div>
      )}
      {validations?.exceedMaximunBookingDays && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>
              Para este grupo você só pode reserva por no máximo&nbsp;
            </span>
            <span className="underline underline-offset-2">
              {companyBranch?.maxBookingDays} dias.
            </span>
            <span>&nbsp;Faça uma nova busca com um intervalo menor.</span>
          </span>
        </div>
      )}
      {validations?.exceedLimitFutureBooking && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>
              Não é possivel reservar nessa data. O limite para reservas é&nbsp;
            </span>
            <span className="underline underline-offset-2">
              {validations?.lastDateAvailableToBook}.&nbsp;
            </span>
            <span>
              Para este grupo você só pode reservar por no máximo
              {companyBranch?.limitEndBookingDays} à frente. Faça uma nova busca
              com datas mais recentes.
            </span>
          </span>
        </div>
      )}
      {!validations?.openForStartDate?.isOpen && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            <span>Não é possivel reservar para a data de retirada.</span>
            <span>
              {validations?.openForStartDate?.openHour &&
              validations?.openForStartDate?.closeHour
                ? ` O horário de funcionamento da locadora é ${t(validations?.openForStartDate?.day)} de ${validations?.openForStartDate?.openHour} até ${validations?.openForStartDate?.closeHour}. `
                : " Não há horário de funcionamento dia de retirada. "}
            </span>
          </span>
        </div>
      )}
      {!validations?.openForEndDate?.isOpen && (
        <div className="bg-white p-2 border-2 border-stone-200 font-bold">
          <WarningOutlined style={{ color: "#FCA311" }} />
          <span>&nbsp;&nbsp;</span>
          <span>
            Não é possivel reservar para a data de devolução.
            {validations?.openForEndDate?.openHour &&
            validations?.openForEndDate?.closeHour
              ? ` O horário de funcionamento da locadora é ${t(validations?.openForEndDate?.day)} de ${validations?.openForEndDate?.openHour} até ${validations?.openForEndDate?.closeHour}. `
              : " Não há horário de funcionamento no dia de devolução. "}
          </span>
        </div>
      )}
    </div>
  )
}

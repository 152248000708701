import React from "react"

import { Form, InputNumber } from "antd"
import Text from "components/atom/text"

interface FormNumberProps {
  className?: string
  defaultValue?: number
  disabled?: boolean
  label?: string
  max?: number
  min?: number
  name: string
  required?: boolean
}

const baseClassNames =
  "rounded-md bg-white text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"

export default function FormInputNumber(props: FormNumberProps) {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  return (
    <div>
      {props.label && (
        <Text className="block text-sm/6 font-semibold text-gray-900 mb-1">
          {props.label}
          {props.required && " *"}
        </Text>
      )}

      <Form.Item
        className={props.className}
        hasFeedback
        name={props.name}
        rules={rules}
      >
        <InputNumber
          className={baseClassNames}
          defaultValue={props.defaultValue}
          disabled={props.disabled || false}
          max={props.max}
          min={props.min}
          size="large"
          style={{ width: "100%" }}
        />
      </Form.Item>
    </div>
  )
}

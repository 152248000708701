import Config, { Environment } from "@/libs/config"

const apis: Record<string, string> = {
  [Environment.Development]: "http://localhost:3030",
  [Environment.Homolog]: "https://dev-api.closecar.com.br/discount",
  [Environment.Production]: "https://api.closecar.com.br/discount",
}

export const discountApi = () => {
  const enviroment = Config.environment.discount

  return apis[enviroment]
}

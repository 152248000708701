import { Dispatch } from "redux"

import FetchApi from "@/libs/fetch"
import { mappedApis } from "@/libs/fetch/apis"
import { ErrorPayload } from "@/libs/fetch/interface"
import Storage from "@/libs/storage"
import { SignupParams, SignupCompanyParams } from "@/store/signup/Action"
import ActionType from "@/store/signup/ActionType"
import * as ActionUtility from "@/store/utilities/actionUtility"

export const signup = async ({
  acceptTerms,
  cpf,
  email,
  name,
  password,
}: SignupParams) => {
  const fetch = await FetchApi.post({
    params: {
      acceptTerms: acceptTerms ? 1 : 0,
      cpf,
      email,
      name,
      password,
    },
    url: mappedApis.auth.signup.post.url,
  })

  if (
    fetch.status === FetchApi.HttpSuccessfulOk ||
    fetch.status === FetchApi.HttpSuccessfulCreated
  ) {
    Storage.authorization(fetch.data)
  }

  return {
    data: fetch.data,
    hasError: fetch.status === FetchApi.HttpErrorBadRequest,
  }
}

export const signupCompany = async ({
  acceptTerms,
  documentLegalNumber,
  email,
  name,
  password,
  phone,
  tradingName,
}: SignupCompanyParams) => {
  const fetch = await FetchApi.post({
    params: {
      acceptTerms: acceptTerms ? 1 : 0,
      documentLegalNumber,
      email,
      name,
      password,
      phone,
      tradingName,
    },
    url: mappedApis.base.company.start.post.url,
  })

  if (
    fetch.status === FetchApi.HttpSuccessfulOk ||
    fetch.status === FetchApi.HttpSuccessfulCreated
  ) {
    Storage.authorization(fetch.data)
  }

  return {
    data: fetch.data,
    hasError: fetch.status === FetchApi.HttpErrorBadRequest,
  }
}

export const setLoading = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNUP_LOADING, payload))

export const setSuccess = (dispatch: Dispatch, payload: boolean) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNUP_SUCCESS, payload))

export const setError = (dispatch: Dispatch, payload: ErrorPayload | null) =>
  dispatch(ActionUtility.createAction(ActionType.SIGNUP_ERROR, payload))

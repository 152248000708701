import React from "react"
import { useHistory } from "react-router-dom"

import ImageLoad from "components/atom/image_load"
import Menu from "components/molecule/menu"

import { useAccountStore } from "@/store/account/useAccountStore"

import "./styles.scss"

enum MenuKeys {
  Dashboard = "key_dashboard",
  Booking = "key_booking",
  Vehicle = "key_vehicle",
  VehicleFull = "key_vehicle_full",
  VehicleGroup = "key_vehicle_group",
  VehicleGroupType = "key_vehicle_group_type",
  Discount = "key_discount",
  FinancialVehicle = "key_financial_vehicle",
  Financial = "key_financial",
  User = "key_user",
  Client = "key_client",
  Quote = "key_quote",
  Config = "key_config",
  Services = "key_services",
  CompanyBranch = "key_company_branch",
  BookingAdditional = "key_booking_additional",
}

function MenuCompany() {
  const { accountInfo, executeAccountLogout } = useAccountStore()

  const history = useHistory()

  const onLogout = () => {
    executeAccountLogout()
    history.push(`/`)
  }

  const isAllowed = (name: string) => {
    return (
      accountInfo?.data?.allowedMenus?.some(
        (menu: any) => menu.name === name,
      ) || false
    )
  }

  return (
    <div className="content_menu_company">
      <div className="_text-center _mt-8 _mb-6">
        <ImageLoad
          className="molecule-menu-closecar-logo"
          url="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal_white.png"
        />
      </div>

      <div className="text-center mt-6 mb-6">
        <span>Bem vindo,</span>
        <br />
        <span className="font-bold text-lg">{accountInfo.data?.name}</span>
        <div className="mt-4 flex justify-center">
          <img className="w-[96px]" src={accountInfo.data?.logo} />
        </div>
      </div>

      {isAllowed(MenuKeys.Dashboard) && (
        <Menu name="Painel geral" url="/account/company/dashboard" />
      )}

      {isAllowed(MenuKeys.Booking) && (
        <Menu name="Reservas" url="/account/company/book" />
      )}

      {isAllowed(MenuKeys.Vehicle) && (
        <Menu name="Grupo de veículos" url="/account/company/vehicle" />
      )}

      {isAllowed(MenuKeys.Client) && (
        <Menu name="Meus clientes" url="/account/company/client" />
      )}

      {isAllowed(MenuKeys.Discount) && (
        <Menu name="Promoções & Descontos" url="/account/company/discount" />
      )}

      {isAllowed(MenuKeys.Financial) && (
        <Menu name="Painel financeiro" url="/account/company/finance" />
      )}

      {isAllowed(MenuKeys.User) && (
        <Menu name="Usuários da plataforma" url="/account/company/user" />
      )}

      {isAllowed(MenuKeys.CompanyBranch) && (
        <Menu name="Unidades" url="/account/company/branch" />
      )}

      {isAllowed(MenuKeys.BookingAdditional) && (
        <Menu
          name="Adicionais da reserva"
          url="/account/company/book/additional"
        />
      )}

      {isAllowed(MenuKeys.VehicleGroupType) && (
        <Menu
          name="Tipo de grupo de veículo"
          url="/account/company/vehicle/type"
        />
      )}

      {isAllowed(MenuKeys.Config) && (
        <Menu
          name="Meus Dados"
          url={`/account/company/config/${accountInfo.data?.uCodeCompany}`}
        />
      )}

      {/* {isAllowed(MenuKeys.Services) && (
        <Menu name="Serviços" url="/account/company/services" />
      )} */}

      <Menu name="Sair" onClick={onLogout} withIcon={false} />
    </div>
  )
}

export default MenuCompany

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Alert, Form, Skeleton } from "antd"
import Button from "components/atom/button"
import { Rendering } from "components/molecule/loading"
import StateMachine, { StateMachineType } from "libs/statemachine"
import TakePhotoDocumentModal, {
  DocumentUploadType,
  useTakePhotoDocument,
} from "pages/UserAccount/takePhotoDocumentModal"

import ManageDate from "@/libs/date"
import { DEFAULT_ERROR_MESSAGE } from "@/libs/i18n/pt"
import { useAccountStore } from "@/store/account/useAccountStore"

import AccountUserInfo from "./accountUserInfo"
import "./styles.scss"

const AccountUserData = () => {
  const { t } = useTranslation()

  const {
    accountInfo,
    accountUpdate,
    executeAccountInfo,
    executeAccountUpdate,
  } = useAccountStore()

  useEffect(() => {
    executeAccountInfo()
  }, [])

  const [form] = Form.useForm()

  const { ref: refProofResidence, show: showProofResidence } =
    useTakePhotoDocument()
  // const { ref: refProofAdditionalDocument, show: showProofAdditionalDocument } =
  //   useTakePhotoDocument()

  const onSuccessPhotoEndereco = (data: any) => {
    executeAccountUpdate({
      document: {
        type: "document_proof_residence",
        value: data.fileKey,
      },
    })
  }

  // const onSuccessPhotoAddtionalDocument = (data: any) => {
  //   executeAccountUpdate({
  //     document: {
  //       type: "document_proof_additional_document",
  //       value: data.fileKey,
  //     },
  //   })
  // }

  const onSubmitForm = () => {
    form.submit()
  }

  const onSubmitPersonalData = (form: any) => {
    const submitData = {
      account: {
        birthday: ManageDate.formatDatabaseDate(form["account.birthday"]),
        cpf: form["account.cpf"],
        gender: form["account.gender"] || "",
        name: form["account.name"],
        phone: form["account.phone"],
      },
      address: {
        city: form["address.city"],
        complement: form["address.complement"] || "",
        neighborhood: form["address.neighborhood"],
        number: form["address.number"],
        state: form["address.state"],
        street: form["address.street"],
        typeStreet: form["address.typeStreet"],
        zipCode: form["address.zipCode"],
      },
    }

    executeAccountUpdate(submitData)
  }

  const renderStateNotStarted = () => <Rendering className="_mb-6" />

  const renderStateLoading = () => (
    <div className="ml-4 md:ml-0">
      <Skeleton active />
    </div>
  )

  const renderStateLoaded = () => (
    <div className="mb-6 mx-6 md:mx-0 grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-6">
        <div className="_text-6 _mb-5"> Dados Pessoais </div>
        <Form form={form} onFinish={onSubmitPersonalData}>
          <AccountUserInfo form={form} />

          {StateMachine.isError(accountUpdate.viewState) && (
            <Alert
              className="mb-4"
              message={t([accountUpdate.data.key, DEFAULT_ERROR_MESSAGE])}
              type="error"
            />
          )}

          <Button
            block
            className="_button_default_action"
            loading={StateMachine.isLoading(accountUpdate?.viewState)}
            onClick={() => onSubmitForm()}
            theme="primary"
            type="button"
          >
            Salvar dados
          </Button>
        </Form>
      </div>
      <div className="col-span-12 md:col-span-6">
        <div className="_text-6"> Documentos </div>
        <div className="_mb-5">
          Faça o upload dos arquivos dos seus documentos.
        </div>

        <TakePhotoDocumentModal
          documentType={DocumentUploadType.PROOF_RESIDENCE}
          initalPhotoDocumentUrl={accountInfo.data?.proofResidencePhotoUrl}
          onSuccess={onSuccessPhotoEndereco}
          ref={refProofResidence}
          title="Foto do comprovante de endereço"
        />

        {/* <TakePhotoDocumentModal
          documentType={DocumentUploadType.PROOF_ADDITIONAL_DOCUMENT}
          initalPhotoDocumentUrl={
            accountInfo.data?.proofAdditionalDocumentPhotoUrl
          }
          onSuccess={onSuccessPhotoAddtionalDocument}
          ref={refProofAdditionalDocument}
          title="Foto de documento com foto complementar"
        /> */}

        <Button
          block
          className="_mt-4 _button_default_action"
          loading={StateMachine.isLoading(accountUpdate?.viewState)}
          onClick={() => showProofResidence()}
          theme="primary"
          type="button"
        >
          {accountInfo.data?.proofResidencePhotoUrl ? "Ver" : "Enviar"} foto do
          comprovante de endereço
        </Button>

        {/* <Button
          block
          className="_mt-4 _button_default_action"
          loading={StateMachine.isLoading(accountUpdate?.viewState)}
          onClick={() => showProofAdditionalDocument()}
          theme="primary"
          type="button"
        >
          {accountInfo.data?.proofAdditionalDocumentPhotoUrl
            ? "Ver "
            : "Enviar "}
          foto de documento com foto complementar
        </Button> */}
      </div>
    </div>
  )

  const rendering = () => {
    switch (accountInfo.viewState) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
    }
  }

  return <>{rendering()}</>
}

export default AccountUserData

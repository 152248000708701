import React from "react"

import { Form, Input, Spin } from "antd"
import Text from "components/atom/text"

export enum InputValidateStatus {
  Error = "error",
  Warning = "warning",
  Success = "success",
  Validating = "validating",
}

export enum InputMask {
  Phone = "99 9 9999 9999",
  ResidentialPhone = "99 9999 9999",
  FreePhone = "9999 999 9999",
  DynamicPhone = "999",
  Hours = "99:99",
  Fee = "99",
  FeeDecimal = "99,99",
  CNPJ = "99.999.999/9999-99",
  CPF = "999.999.999-99",
  CEP = "99999-999",
}

interface FormInputProps {
  allowClear?: boolean
  boxClassName?: string
  className?: string
  customRules?: any[]
  dependencies?: any
  disabled?: boolean
  extra?: any
  hasFeedback?: boolean
  hidden?: boolean
  initialValue?: string
  inputClassName?: string
  label?: string
  loading?: boolean
  mask?: InputMask
  max?: number
  min?: number
  name: string
  normalize?: any
  onBlur?: any
  onChange?: (value: any) => void
  onPressEnter?: any
  placeholder?: string
  required?: boolean
  type?: string
  validateStatus?: InputValidateStatus
}

const maskString = (str: string, pattern: string) => {
  let countSubstitutions = 0

  const cleanPattern = () => {
    return pattern.split("").filter((item) => item !== "#")
  }

  const newStr = str.split("").reduce((result, item): any => {
    const remove = cleanPattern()
    const checked = remove.indexOf(item) === -1

    if (checked) {
      return [...result, item]
    }

    return result
  }, [])

  const result = pattern.split("").reduce((result, item): any => {
    if (countSubstitutions === newStr.length) {
      return result
    }

    if (item === "#") {
      const newValue = newStr[countSubstitutions]
      countSubstitutions = countSubstitutions + 1
      return [...result, newValue]
    }

    return [...result, item]
  }, [])

  return result.join("")
}

export const makedPhoneMask = (str: string) => {
  return maskString(str, InputMask.Phone)
}

const dynamicPhoneMask = (str: string) => {
  const size = str.length

  if (size > 2) {
    const noWhitespace = str.replace(/\s/g, "")
    const decision = Number(noWhitespace.charAt(2))
    const firstTwo = str.slice(0, 2)

    if (firstTwo === "08") {
      // Used for 0800
      return InputMask.FreePhone
    } else if (decision < 5) {
      // Used for residencial numbers;
      return InputMask.ResidentialPhone
    } else {
      // Used for mobile numbers;
      return InputMask.Phone
    }
  }

  return InputMask.DynamicPhone
}

const baseClassNames =
  "rounded-md bg-white text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400"

const FormInputItem = ({ loading, onChange, value, ...props }: any) => {
  const maskPattern = /[9#]/g

  const standardMask = (mask: string) => {
    return mask.replace(/9/g, "#")
  }

  const findNextValidMaskDigit = (mask: string, digit: number): string => {
    const currentDigit = mask[digit]

    if (currentDigit.match(maskPattern)) {
      return currentDigit
    } else {
      return findNextValidMaskDigit(mask, digit + 1)
    }
  }

  const findPreviousValidMaskDigit = (mask: string, digit: number): string => {
    const currentDigit = mask[digit] || mask[0]

    if (currentDigit.match(maskPattern)) {
      return currentDigit
    } else {
      return findPreviousValidMaskDigit(mask, digit - 1)
    }
  }

  const onInternalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.mask) {
      const element = e.target
      const currentValue = element.value
      const cursorPosition = element.selectionStart || 0
      const currentChar = e.target.value.charAt(cursorPosition - 1)
      const increasing = (currentValue?.length || 0) > (value?.length || 0)
      let currentMask = props.mask
      let maskDigit = null

      switch (props.mask) {
        case InputMask.DynamicPhone:
          currentMask = dynamicPhoneMask(currentValue)
          break
      }

      // Check if reach the end of mask validation;
      if (cursorPosition > currentMask.length) {
        e.preventDefault()
        e.stopPropagation()
        onChange(currentValue.substring(0, currentValue.length - 1))
        return
      }

      if (increasing) {
        maskDigit = findNextValidMaskDigit(currentMask, cursorPosition - 1)
      } else {
        maskDigit = findPreviousValidMaskDigit(currentMask, cursorPosition - 1)
      }

      // Verify if it is a number digit;
      if (maskDigit === "9") {
        const checkIfIsNumber = parseInt(currentChar)
        if (
          isNaN(checkIfIsNumber) ||
          checkIfIsNumber < 0 ||
          checkIfIsNumber > 9
        ) {
          e.preventDefault()
          e.stopPropagation()
          onChange(currentValue.substring(0, currentValue.length - 1))
          return
        }
      }

      const newValue = maskString(currentValue, standardMask(currentMask))

      if (cursorPosition < currentValue.length) {
        setTimeout(() => {
          element.selectionStart = cursorPosition
          element.selectionEnd = cursorPosition
        }, 60)
      }

      onChange(newValue)
    } else {
      onChange(e.target.value)
    }
  }

  return (
    <>
      {props?.type === "password" ? (
        <Input.Password
          allowClear={props.allowClear || false}
          className={`${props.inputClassName} ${baseClassNames}`}
          disabled={props.disabled || false}
          onChange={onInternalChange}
          onPressEnter={props.onPressEnter}
          size="large"
          value={value}
        />
      ) : (
        <Input
          addonAfter={loading ? <Spin size="small" /> : null}
          allowClear={props.allowClear || false}
          className={`${props.inputClassName} ${baseClassNames}`}
          disabled={props.disabled || false}
          hidden={props.hidden || false}
          onBlur={props.onBlur}
          onChange={onInternalChange}
          onPressEnter={props.onPressEnter}
          placeholder={props.placeholder || undefined}
          size="large"
          value={value}
        />
      )}
    </>
  )
}

const FormInput: React.FC<FormInputProps> = (props) => {
  const rules: any = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  if (props?.min) {
    rules.push({
      message: `Este campo deve ter no mínimo ${props?.min} letras.`,
      min: props?.min,
    })
  }

  if (props?.max) {
    rules.push({
      max: props?.max,
      message: `Este campo deve ter no máximo ${props?.max} letras.`,
    })
  }

  if (props?.type === "email") {
    rules.push({
      message: `Este campo deve ser um email válido`,
      type: "email",
    })
  }

  if (props?.customRules) {
    props?.customRules.forEach((rule) => {
      rules.push(rule)
    })
  }

  return (
    <div
      className={`${props.boxClassName} ${props.hidden ? "_display-none" : ""}`}
    >
      {props.label && (
        <Text className="block text-sm/6 font-semibold text-gray-900 mb-1">
          {props.label}
          {props.required && " *"}
        </Text>
      )}

      <Form.Item
        className={props.className}
        dependencies={props.dependencies}
        extra={props.extra}
        hasFeedback={props.hasFeedback || false}
        initialValue={props.initialValue}
        name={props.name}
        rules={rules}
        validateStatus={props.validateStatus}
      >
        <FormInputItem {...props} />
      </Form.Item>
    </div>
  )
}

export default FormInput

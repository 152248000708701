import { StateMachineType } from "libs/statemachine"
import { Dispatch } from "redux"
import * as ExecuterEffect from "store/executer/Effect"
import { HttpVerb } from "store/executer/Effect"

import { mappedApis } from "@/libs/fetch/apis"

export enum FeatureExecuter {
  AccountInternalCreate = "ACCOUNT_INTERNAL_CREATE",
  AccountFirstAccess = "ACCOUNT_FIRST_ACCESS",
  VehicleEndRent = "END_RENT",
  VehicleStartRent = "START_RENT",
  VehicleRejectRent = "REJECT_RENT",
  VehicleCancelRent = "CANCEL_RENT",
  VehicleConfirmRent = "CONFIRM_RENT",
  VehicleNoShowRent = "NO_SHOW_RENT",
  VehicleExtraInfoRent = "EXTRA_INFO_RENT",
  GroupManualBook = "GROUP_MANUAL_BOOK",
  GroupManualBookCancel = "GROUP_CANCEL_MANUAL_RENT",
  VehicleManualBook = "MANUAL_BOOK",
  CancelManualRent = "CANCEL_MANUAL_RENT",
  BookingRating = "BOOK_RATING",
  BookingCharge = "BOOKING_CHARGE",
  CompanyContact = "COMPANY_CONTACT",
  BookingAvailable = "BOOKING_AVAILABLE",
  Booking = "BOOKING",
  BookingCompany = "BOOKING_COMPANY",
  AddRemoveCompanyCities = "ADD_REMOVE_COMPANY_CITIES",
  VehicleGroupAdditionalLinking = "VEHICLE_GROUP_ADDITIONAL_LINKING",
  VehicleGroupPrice = "VEHICLE_GROUP_PRICE",
  VehicleGroupPriceDates = "VEHICLE_GROUP_PRICE_DATES",
  VehicleGroupStock = "VEHICLE_GROUP_STOCK",
  CompanyBranchPickupAdd = "ADD_COMPANY_BRANCH_PICKUP",
  CompanyBranchDropoffAdd = "ADD_COMPANY_BRANCH_DROPOFF",
  CompanyBranchOpenDaysAdd = "ADD_COMPANY_BRANCH_OPEN_DAYS",
  VehicleGroupBlockDates = "VEHICLE_GROUP_BLOCK_DATES",
  CompanyBranchBlockDates = "ADD_COMPANY_BRANCH_BLOCK_DATES",
  SignInAdmin = "SIGN_IN_ADMIN",
}

export const features = {
  [FeatureExecuter.AccountFirstAccess]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.auth.firstAccess.put.url,
  },
  [FeatureExecuter.AccountInternalCreate]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.account.internalCreate.post.url,
  },
  [FeatureExecuter.AddRemoveCompanyCities]: {
    httpVerb: HttpVerb.Put,
    url: mappedApis.base.company.myCities.put.url,
  },
  [FeatureExecuter.Booking]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.booking.booking.post.url,
  },
  [FeatureExecuter.BookingAvailable]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.booking.available.post.url,
  },
  [FeatureExecuter.BookingCharge]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.booking.charge.post.url,
  },
  [FeatureExecuter.BookingCompany]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.booking.company.post.url,
  },
  [FeatureExecuter.BookingRating]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.booking.rating.post.url,
  },
  [FeatureExecuter.CancelManualRent]: {
    httpVerb: HttpVerb.Delete,
    url: mappedApis.vehicle.manualBooking.delete.url,
  },
  [FeatureExecuter.CompanyBranchBlockDates]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupBranchBlockDates.post.url,
  },
  [FeatureExecuter.CompanyBranchDropoffAdd]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.companyBranch.dropoff.post.url,
  },
  [FeatureExecuter.CompanyBranchOpenDaysAdd]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.companyBranch.opendays.post.url,
  },
  [FeatureExecuter.CompanyBranchPickupAdd]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.companyBranch.pickup.post.url,
  },
  [FeatureExecuter.CompanyContact]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.base.company.contact.post.url,
  },
  [FeatureExecuter.GroupManualBook]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupManualBook.post.url,
  },
  [FeatureExecuter.GroupManualBookCancel]: {
    httpVerb: HttpVerb.Delete,
    url: mappedApis.vehicle.vehicleGroupManualBook.delete.url,
  },
  [FeatureExecuter.SignInAdmin]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.auth.signinAdmin.post.url,
  },
  [FeatureExecuter.VehicleCancelRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingCancel.post.url,
  },
  [FeatureExecuter.VehicleConfirmRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingConfirm.post.url,
  },
  [FeatureExecuter.VehicleEndRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingEnd.post.url,
  },
  [FeatureExecuter.VehicleExtraInfoRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingExtraInfo.post.url,
  },
  [FeatureExecuter.VehicleGroupAdditionalLinking]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupBookingAdditionalLinking.post.url,
  },
  [FeatureExecuter.VehicleGroupBlockDates]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupBlockDates.post.url,
  },
  [FeatureExecuter.VehicleGroupPrice]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupPrice.post.url,
  },
  [FeatureExecuter.VehicleGroupPriceDates]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupPriceDates.post.url,
  },
  [FeatureExecuter.VehicleGroupStock]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.vehicleGroupStock.post.url,
  },
  [FeatureExecuter.VehicleManualBook]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.manualBooking.post.url,
  },
  [FeatureExecuter.VehicleNoShowRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingNoShow.post.url,
  },
  [FeatureExecuter.VehicleRejectRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingReject.post.url,
  },
  [FeatureExecuter.VehicleStartRent]: {
    httpVerb: HttpVerb.Post,
    url: mappedApis.vehicle.bookingStart.post.url,
  },
}

export const executeAction =
  ({
    bodyParams = {},
    feature,
    pathParams = {},
    signal,
  }: {
    feature: FeatureExecuter
    bodyParams?: any
    pathParams?: any
    signal?: AbortSignal
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const dataFeature = features[feature]

      const url = dataFeature.url

      ExecuterEffect.setViewState(dispatch, {
        feature,
        viewState: StateMachineType.Loading,
      })

      const urlWithPathParams =
        Object.keys(pathParams).reduce((result: string, key: string) => {
          return result.replace(`{${key}}`, pathParams[key])
        }, url) || url

      const result = await ExecuterEffect.executeAction({
        bodyParams,
        httpVerb: dataFeature.httpVerb,
        signal,
        url: `${urlWithPathParams}`,
      })

      if (result.hasError) {
        ExecuterEffect.setData(dispatch, {
          data: { data: result?.data || null, hasError: true },
          feature,
        })
        ExecuterEffect.setViewState(dispatch, {
          feature,
          viewState: StateMachineType.Error,
        })
      } else {
        ExecuterEffect.setData(dispatch, {
          data: result?.data || null,
          feature,
        })

        ExecuterEffect.setViewState(dispatch, {
          feature,
          viewState: StateMachineType.Loaded,
        })
      }

      return result
    } catch (e) {
      if (e instanceof Error && e.name === "AbortError") {
        return
      }
      const dataError = { data: null, hasError: true }

      ExecuterEffect.setData(dispatch, {
        data: dataError,
        feature,
      })

      ExecuterEffect.setViewState(dispatch, {
        feature,
        viewState: StateMachineType.Error,
      })

      return dataError
    }
  }

export const executeReset =
  ({ feature }: { feature: FeatureExecuter }) =>
  async (dispatch: Dispatch) => {
    ExecuterEffect.setData(dispatch, { data: null, feature })

    ExecuterEffect.setViewState(dispatch, {
      feature,
      viewState: StateMachineType.NotStarted,
    })
  }

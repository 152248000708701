import React, { useEffect, useState } from "react"
import { DayPicker } from "react-day-picker"

import { DatePicker as AntDatePicker, Drawer, Form } from "antd"
import moment, { Moment } from "moment"
import { ptBR } from "react-day-picker/locale"

import useDeviceDetection from "@/hooks/useDeviceDetection"
import ManageDate from "@/libs/date"

import FormSelect from "../form_select"
import "./style.css"

const baseClassNames =
  "rounded-md bg-white text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"

const optionsYears = () => {
  const years = []
  const currentYear = new Date().getFullYear()

  for (let i = currentYear; i >= 1900; i--) {
    years.push({ text: i, value: i })
  }

  return years
}

const DatePicker = (props: any) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Moment>(ManageDate.now())
  const device = useDeviceDetection()

  useEffect(() => {
    if (props.value) {
      setSelected(props.value)
    }
  }, [props.value])

  const { hideNavigation, numberOfMonths, reverseMonths, withSelectYear } =
    props?.mobileOptions || {}

  const onOpenDrawerTimeMobile = () => {
    setOpen(true)

    setTimeout(() => {
      const value = selected?.format("YYYY-MM-DD")

      if (value) {
        const elements = document.querySelectorAll(`td[data-day="${value}"]`)
        elements.forEach((element) => {
          element?.scrollIntoView({ behavior: "smooth", block: "center" })
        })
      }
    })
  }

  const onClose = (date?: Moment) => {
    console.log(date, selected)
    props.onSelectMobile?.(date || selected)
    setOpen(false)
  }

  const onSelectDate = (value: Date) => {
    const mDate = moment(value)
    setSelected(mDate)
    onClose(mDate)
  }

  const onMonthChange = (date: Date) => {
    setSelected(moment(date))
  }

  const onSelectYear = (option: { text: any; value: any }) => {
    const currentDate = selected || moment()
    const newDate = ManageDate.changeYear(
      currentDate.format("YYYY-MM-DD"),
      option.value,
    )
    setSelected(newDate || moment())
  }

  if (device === "mobile") {
    return (
      <>
        <Drawer
          closable
          onClose={() => onClose()}
          open={open}
          placement="bottom"
          size="large"
          title="Selecione uma data"
        >
          <div className="w-full flex flex-col items-center">
            {withSelectYear && (
              <div className="w-full">
                <Form initialValues={{ selectYear: selected.format("YYYY") }}>
                  <FormSelect
                    label="Selecione o ano"
                    name="selectYear"
                    onSelect={onSelectYear}
                    option={optionsYears()}
                    selectClassName="w-full"
                  />
                </Form>
              </div>
            )}
            <DayPicker
              classNames={{ months: "w-full" }}
              hideNavigation={hideNavigation || false}
              locale={ptBR}
              mode="single"
              month={selected?.toDate()}
              numberOfMonths={numberOfMonths || 6}
              onMonthChange={onMonthChange}
              onSelect={onSelectDate}
              required
              reverseMonths={reverseMonths}
              selected={selected?.toDate()}
            />
          </div>
        </Drawer>
        <AntDatePicker
          className={`${baseClassNames} datepicker-disabled-for-mobile`}
          format="DD/MM/YYYY"
          inputReadOnly
          onClick={onOpenDrawerTimeMobile}
          open={false}
          size="large"
          style={{
            width: "100%",
          }}
          value={selected}
        />
      </>
    )
  }

  return (
    <AntDatePicker
      {...props}
      className={baseClassNames}
      defaultValue={props.defaultValue}
      format="DD/MM/YYYY"
      onOk={props.onSelect}
      onSelect={props.onSelect}
      size="large"
      style={{
        width: "100%",
      }}
    />
  )
}

export default DatePicker

import history from "libs/history"
import Storage from "libs/storage"
import slugify from "slugify"

import { mappedApis } from "./apis"

const signalRequests: Record<string, AbortController> = {}

// Isso irá substituir a conversão das datas que vem como instancia do Moment;
const replacer = function (key: any, value: any) {
  // @ts-expect-error - this has no type;
  const internal = this as any

  const getValue = (internal[key] as any) || null
  if (getValue?._isAMomentObject) {
    return internal[key].format()
  }

  return value
}

class FetchApi {
  static get HttpSuccessfulOk() {
    return 200
  }

  static get HttpSuccessfulCreated() {
    return 201
  }

  static get HttpErrorBadRequest() {
    return 400
  }

  static get HttpErrorUnauthorized() {
    return 401
  }

  static get HttpErrorForbidden() {
    return 403
  }

  static get HttpErrorNotFound() {
    return 404
  }

  static hasError(code: number) {
    return code >= FetchApi.HttpErrorBadRequest
  }

  static async refreshToken(
    url: any,
    params: any,
    hasAuth = false,
    method: any,
    shouldRedirect: boolean = true,
    optionsRequest = {},
  ) {
    const authorization = Storage.authorization()

    const options: any = {
      headers: {
        Authorization: authorization?.refreshToken,
        "user-time-zone":
          Intl.DateTimeFormat().resolvedOptions().timeZone ||
          "America/Sao_Paulo",
      },
      method: "GET",
    }

    const refreshUrl = mappedApis.auth.refresh.get.url

    const response = await fetch(refreshUrl, options)
    const data = await response.json()

    if (response.status === FetchApi.HttpSuccessfulOk) {
      Storage.authorization(data)

      if (method === "GET") {
        return await FetchApi.get({
          hasAuth,
          options: optionsRequest,
          shouldRedirect,
          url,
        })
      } else if (method === "POST") {
        return await FetchApi.post({
          hasAuth,
          options: optionsRequest,
          params,
          url,
        })
      } else if (method === "PUT") {
        return await FetchApi.put({
          hasAuth,
          options: optionsRequest,
          params,
          url,
        })
      } else if (method === "DELETE") {
        return await FetchApi.delete({
          hasAuth,
          options: optionsRequest,
          params,
          url,
        })
      }
    } else {
      Storage.clear()

      if (shouldRedirect) {
        const params: any = history.location.state

        const urlToRedirect = params?.urlToRedirect
          ? params.urlToRedirect
          : history.location.pathname

        history.replace("/signin", {
          dataToRedirect: params?.dataToRedirect,
          messageToRedirect: null,
          redirect: true,
          urlToRedirect,
        })
      }

      return {
        data,
        status: FetchApi.HttpErrorUnauthorized,
      }
    }
  }

  static async get({
    hasAuth = false,
    options = {},
    shouldRedirect = true,
    url,
  }: {
    url: string
    hasAuth?: boolean
    options?: { signal?: AbortSignal; autoAbort?: boolean }
    shouldRedirect?: boolean
  }): Promise<{ data: any; status: number }> {
    const keySignal = slugify(url)
    const previousController = signalRequests[keySignal]
    let currentSignal = options.signal

    if (options.autoAbort) {
      if (previousController) {
        previousController?.abort()
      }

      const abortController = new AbortController()
      signalRequests[keySignal] = abortController
      currentSignal = abortController.signal
    }

    let fetchOptions: RequestInit = {
      headers: {
        "user-time-zone":
          Intl.DateTimeFormat().resolvedOptions().timeZone ||
          "America/Sao_Paulo",
      },
      method: "GET",
      signal: currentSignal,
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      fetchOptions = {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: authorization?.accessToken,
        },
      }
    }

    const response = await fetch(url, fetchOptions)

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(
        url,
        null,
        hasAuth,
        "GET",
        shouldRedirect,
        options,
      )
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async post({
    hasAuth = false,
    options = {},
    params,
    url,
  }: {
    url: string
    params: any
    hasAuth?: boolean
    options?: any
  }): Promise<any> {
    let headers: any = {
      "Content-Type": "application/json",
      "user-time-zone":
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      headers = { ...headers, Authorization: authorization?.accessToken }
    }

    const response = await fetch(url, {
      body: JSON.stringify(params, replacer),
      headers,
      method: "POST",
      ...options,
    })

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(url, params, hasAuth, "POST")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async put({
    hasAuth = false,
    options = {},
    params,
    url,
  }: {
    url: string
    params?: any
    hasAuth?: boolean
    options?: any
  }): Promise<any> {
    let headers: any = {
      "Content-Type": "application/json",
      "user-time-zone":
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      headers = { ...headers, Authorization: authorization?.accessToken }
    }

    const response = await fetch(url, {
      body: JSON.stringify(params, replacer),
      headers,
      method: "PUT",
      ...options,
    })

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(url, params, hasAuth, "POST")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async delete({
    hasAuth = false,
    options = {},
    params,
    url,
  }: {
    url: string
    params: any
    hasAuth?: boolean
    options?: any
  }): Promise<any> {
    let headers: any = {
      "Content-Type": "application/json",
      "user-time-zone":
        Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Sao_Paulo",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      headers = { ...headers, Authorization: authorization?.accessToken }
    }

    const response = await fetch(url, {
      body: JSON.stringify(params, replacer),
      headers,
      method: "DELETE",
      ...options,
    })

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(url, params, hasAuth, "POST")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async upload(url: string, type: string, file: any): Promise<any> {
    const response = await fetch(url, {
      body: file,
      method: "PUT",
    })

    return {
      status: response.status,
    }
  }

  static async uploadBase64(
    url: string,
    type: string,
    file: any,
  ): Promise<any> {
    const response = await fetch(url, {
      body: file,
      headers: {
        "Content-Encoding": "base64",
        "Content-Type": "image/jpeg",
      },
      method: "PUT",
    })

    return {
      status: response.status,
    }
  }

  static async download(
    url: string,
    fileName: string | undefined,
    hasAuth = false,
  ): Promise<any> {
    let options: any = {
      method: "GET",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      options = {
        ...options,
        headers: { Authorization: authorization?.accessToken },
      }
    }

    const response = await fetch(url, options)

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(url, null, hasAuth, "GET")
    } else {
      const data = await response.blob()

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", fileName ? fileName : "CloseCarDocumento")
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)

      return {
        data,
        status: response.status,
      }
    }
  }
}

export default FetchApi
